import React, { useState, useEffect, useContext, useRef } from "react";
import { Tab, Tabs, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from "axios";
import Cookies from 'universal-cookie';
import { withRouter, generatePath } from "react-router-dom";
import {FormattedMessage, useIntl} from 'react-intl';
import Pagination from "./pagination";
import { RouteContext } from "../../contexts/route.context";
import { translateTerm } from "../../util/helper";
import { useIntersection } from "../../util/useIntersection";
import { QRCode } from 'react-qrcode-logo';
import renterLogo from '../../assets/images/qrlogo.png';


const Profile = ({ history }) => {
  const intl = useIntl();
  const cookies = new Cookies();
  const { baseURL, apiHeaders, getUserPosts, userPosts, userPostsLoading, setUserPostsLoading, getMe, me, getPostReviews, postReviews, 
    postReviewsLoading, setPostReviewsLoading, } = useContext(RouteContext);
  const [key, setKey] = useState('posts');
  const [numLease, setNumLease] = useState(12);
  const [pageLease, setpageLease] = useState(1);
  const [numReview, setNumReview] = useState(5);
  const [pageReview, setpageReview] = useState(1);

  const [userProducts, setUserProducts] = useState();
  const [userProductsReview, setUserProductsReview] = useState();
  const [productsLoading, setProductsLoading] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [noMorePosts, setNoMorePosts] = useState(false);

  const elementRef = useRef(null);
  const isVisible = useIntersection(elementRef, "0px 0px 30% 0px");
  

  useEffect(() => {
    if (isVisible) {
    // Trigger a function when the div is visible on view port
    triggerFunc();
    }
  }, [isVisible]);

  const triggerFunc = () => {
    let inp = `{user_id: "${me.id}"}`;
    if(key === 'posts'){
      if(userProducts){
        if(userProducts.paginatorInfo.hasMorePages){
          scrollPosts(inp);
        }
        else{
            setNoMorePosts(true);
        }
      }
    }
    else{
      if(userProductsReview){
        if(userProductsReview.paginatorInfo.hasMorePages){
          scrollPosts(inp);
        }
        else{
            setNoMorePosts(true);
        }
      }
    }
    
  }

  const scrollPosts = async (inp) => {
    if(key === 'posts'){
      if(moreLoading == false){
        setMoreLoading(true);
        let result = await getUserPosts(numLease, userProducts.paginatorInfo.currentPage+1, inp);
        if(userProducts.paginatorInfo.hasMorePages){
            result.data.map((dat)=>{
              userProducts.data.push(dat);
            })
            userProducts.paginatorInfo = result.paginatorInfo;
        }
        setMoreLoading(false);
      }
    }
    else{
      if(moreLoading == false){
        setMoreLoading(true);
        let result = await getPostReviews(numLease, userProductsReview.paginatorInfo.currentPage+1, inp);
        if(userProductsReview.paginatorInfo.hasMorePages){
            result.data.map((dat)=>{
              userProductsReview.data.push(dat);
            })
            userProductsReview.paginatorInfo = result.paginatorInfo;
        }
        setMoreLoading(false);
      }
    }
    
  }


  useEffect(() => {
    getMe();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(me) {
      let inp = `{user_id: "${me.id}"}`;
      
      // getPostReviews(numReview, pageReview, me.id);
      // getUserPosts(numLease, pageLease, inp);
      getProducts(inp)
      getReview(me.id)
    }
    window.scrollTo(0, 0);
  }, [me]);
  

  const getReview = async (inp) =>{
    let result = await getPostReviews(numLease, pageLease, inp);
    // console.log("resultresultresult", result)
    setUserProductsReview(result);
    if(result === undefined) {
      setNoMorePosts(true);
    }
    else{
      setNoMorePosts(false);
    }
  }

  const getProducts = async (inp) =>{
    let result = await getUserPosts(numLease, pageLease, inp);
    setUserProducts(result);
  }


  const onClickAddPost = () => {
    // window.location.href = "/add-post";
    history.push("/add-post");
  }

  const onGenerateQr = (post) => {
    let id = post.id;
    // cookies.set('qr_post_id', post.id);
    history.push(generatePath("/new-qr/product/:id", {id}))

  }

  const onDeletePost = (post) => {

    Swal.fire({
      title: 'Do you want to delete post?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#25D676',
      denyButtonText: `Don't delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setUserPostsLoading(true);
        
        let result = await axios({
          url: baseURL,
          headers: apiHeaders,
          method: "post",
          data: {
            query: `
              mutation {
                deletePost(id: ${post.id}){
                  id
                  message
                  status
                }
              }
            `
          }
        });

        if(result.data.data) {
          Swal.fire({
            title: 'Deleted a Post',
            text: "",
            icon: 'success',
            confirmButtonColor: '#ff9035',
          }).then((e) => {
            setUserPostsLoading(false);
            getUserPosts(numLease, pageLease);
            window.location.reload();
          });
        }
        else {

          Swal.fire({
            title: 'Delete Post Failed',
            text: result.data.errors[0].message,
            icon: 'warning',
            confirmButtonColor: '#ff9035',
          }).then((e) => {
            // setUserPostsLoading(false);
            // getUserPosts(numLease, pageLease);
            window.location.reload();
          });

        }

      }
    });
  }


  const onEditPost = (post) => {
    cookies.set('edit_post_id', post.id);
    // window.location.href = "/add-post";
    history.push("/edit-post");
  }


  function starRate(rev) {
    let numStar = parseInt(rev.star);
    const rows = [];
    for (let i = 0; i < numStar; i++) {
        rows.push(
          <svg key={i} width="26" height="25" style={{marginRight:"5px"}} viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2186 1.56079C13.6861 0.125569 15.7166 0.125569 16.1825 1.56079L18.5496 8.84444C18.6515 9.15687 18.8496 9.4291 19.1154 9.62222C19.3813 9.81533 19.7014 9.91945 20.0301 9.91969H27.6893C29.1993 9.91969 29.8257 11.852 28.6056 12.7403L22.4096 17.2407C22.1435 17.4342 21.9454 17.707 21.8438 18.02C21.7422 18.3329 21.7422 18.67 21.844 18.983L24.2095 26.2666C24.677 27.7034 23.033 28.8971 21.8128 28.0088L15.6169 23.5084C15.3506 23.3148 15.0298 23.2105 14.7006 23.2105C14.3713 23.2105 14.0506 23.3148 13.7843 23.5084L7.58833 28.0088C6.36816 28.8971 4.72411 27.7018 5.19161 26.2666L7.55716 18.983C7.65889 18.67 7.65895 18.3329 7.55733 18.02C7.45572 17.707 7.25764 17.4342 6.99149 17.2407L0.795555 12.7403C-0.426178 11.852 0.203389 9.91969 1.71186 9.91969H9.36951C9.69839 9.91978 10.0189 9.81581 10.2851 9.62267C10.5513 9.42953 10.7495 9.15712 10.8515 8.84444L13.2186 1.56079Z" fill="#FFBF05"/>
          </svg>
        );
    }
    return rows;
  }


  return (
    <div>
      <div className="container-fluid lessor-profile-top-div">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12 d-flex">
              <div className="lessor-profile-img-div border">
                <img src={me && me.avatar ? me.avatar : 'assets/images/profpic.svg'} className="imgenclose1" />
              </div>
              <div className="lessor-name-div">
                <p className="mb-2 fw700 cwhite fs30">{me && me.name}</p>
              </div>
              <div className="thisqr-lessor">
                  <QRCode
                  size={120} 
                  enableCORS={true}
                  ecLevel={"L"} 
                  logoImage={renterLogo} 
                  logoHeight={120*0.22} 
                  logoWidth={120*0.22} 
                  logoPaddingStyle={"circle"} 
                  style={{marginRight: intl.locale === "en" ? "0px" : "10px", marginLeft: intl.locale === "ar" ? "0px" : "10px"}}
                  // qrStyle={"fluid"} 
                  value={`https://www.renter.net/lessor-profile/${me?.id}`} 
                  id={"largeqr"}
                  />
                  {/* <p className="mb-0 cgray fs14 d-flex">
                  <svg width="18" height="21" style={{marginRight:"6px", marginLeft:"6px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                  </svg>
                  {me && me.address}
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container main-wrap pb-5">
        <div className="row">
          <div className="col-12 mt-2">

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className={`mb-5 product-categories justify-content-start lessor-tab`}
            >
              <Tab eventKey="posts" title={intl.formatMessage({
                id: 'posts.text',
                defaultMessage: 'Posts',
                description: 'Posts',
              })}>
                
                <div className="row pt-5">

                {!userPostsLoading ?
                  userProducts && userProducts?.data.length !== 0 ? 
                  userProducts?.data.map((post, index) => {
                    return (
                      <div key={index} className="col-12 col-lg-4">
                        <div className="prod-list-single-div">
                          <div className="prod-img-div position-relative">
                            <img src={`${post.post_images.length !== 0 ? post.post_images[0].image : 'assets/images/prod1.png'}`} className="imgenclose" 
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src="assets/images/prod1.png";
                              }}
                            />

                            <span className="generateqr-icon-div" onClick={() => onGenerateQr(post)}>
                              <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinejoin="round" strokeWidth="2" d="M4 4h6v6H4V4Zm10 10h6v6h-6v-6Zm0-10h6v6h-6V4Zm-4 10h.01v.01H10V14Zm0 4h.01v.01H10V18Zm-3 2h.01v.01H7V20Zm0-4h.01v.01H7V16Zm-3 2h.01v.01H4V18Zm0-4h.01v.01H4V14Z"/>
                                <path stroke="currentColor" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01v.01H7V7Zm10 10h.01v.01H17V17Z"/>
                              </svg>
                            </span>

                            <span className="delete-icon-div" onClick={() => onDeletePost(post)}>
                              <svg width="20" height="22" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M10.3998 0.200012C10.1027 0.200171 9.8116 0.283025 9.55896 0.439296C9.30633 0.595567 9.10219 0.819083 8.96941 1.08481L7.81101 3.40001H2.3998C1.97546 3.40001 1.56849 3.56858 1.26843 3.86864C0.968376 4.1687 0.799805 4.57567 0.799805 5.00001C0.799805 5.42436 0.968376 5.83132 1.26843 6.13138C1.56849 6.43144 1.97546 6.60001 2.3998 6.60001V22.6C2.3998 23.4487 2.73695 24.2626 3.33706 24.8628C3.93718 25.4629 4.75111 25.8 5.5998 25.8H18.3998C19.2485 25.8 20.0624 25.4629 20.6625 24.8628C21.2627 24.2626 21.5998 23.4487 21.5998 22.6V6.60001C22.0242 6.60001 22.4311 6.43144 22.7312 6.13138C23.0312 5.83132 23.1998 5.42436 23.1998 5.00001C23.1998 4.57567 23.0312 4.1687 22.7312 3.86864C22.4311 3.56858 22.0242 3.40001 21.5998 3.40001H16.1886L15.0302 1.08481C14.8974 0.819083 14.6933 0.595567 14.4406 0.439296C14.188 0.283025 13.8969 0.200171 13.5998 0.200012H10.3998ZM7.19981 9.80001C7.19981 9.37567 7.36838 8.9687 7.66843 8.66864C7.96849 8.36858 8.37546 8.20001 8.79981 8.20001C9.22415 8.20001 9.63112 8.36858 9.93118 8.66864C10.2312 8.9687 10.3998 9.37567 10.3998 9.80001V19.4C10.3998 19.8244 10.2312 20.2313 9.93118 20.5314C9.63112 20.8314 9.22415 21 8.79981 21C8.37546 21 7.96849 20.8314 7.66843 20.5314C7.36838 20.2313 7.19981 19.8244 7.19981 19.4V9.80001ZM15.1998 8.20001C14.7755 8.20001 14.3685 8.36858 14.0684 8.66864C13.7684 8.9687 13.5998 9.37567 13.5998 9.80001V19.4C13.5998 19.8244 13.7684 20.2313 14.0684 20.5314C14.3685 20.8314 14.7755 21 15.1998 21C15.6242 21 16.0311 20.8314 16.3312 20.5314C16.6312 20.2313 16.7998 19.8244 16.7998 19.4V9.80001C16.7998 9.37567 16.6312 8.9687 16.3312 8.66864C16.0311 8.36858 15.6242 8.20001 15.1998 8.20001Z" fill="#FF0000"/>
                              </svg>
                            </span>

                            <span className="edit-icon-div" onClick={() => onEditPost(post)}>
                              <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M27.8626 4.13772C27.2625 3.53782 26.4487 3.20081 25.6002 3.20081C24.7517 3.20081 23.9379 3.53782 23.3378 4.13772L11.2002 16.2753V20.8001H15.725L27.8626 8.66252C28.4625 8.06243 28.7995 7.24865 28.7995 6.40012C28.7995 5.5516 28.4625 4.73781 27.8626 4.13772Z" fill="white"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M3.2002 9.60002C3.2002 8.75133 3.53734 7.9374 4.13745 7.33728C4.73757 6.73717 5.5515 6.40002 6.4002 6.40002H12.8002C13.2245 6.40002 13.6315 6.5686 13.9316 6.86865C14.2316 7.16871 14.4002 7.57568 14.4002 8.00002C14.4002 8.42437 14.2316 8.83134 13.9316 9.1314C13.6315 9.43145 13.2245 9.60002 12.8002 9.60002H6.4002V25.6H22.4002V19.2C22.4002 18.7757 22.5688 18.3687 22.8688 18.0687C23.1689 17.7686 23.5758 17.6 24.0002 17.6C24.4245 17.6 24.8315 17.7686 25.1316 18.0687C25.4316 18.3687 25.6002 18.7757 25.6002 19.2V25.6C25.6002 26.4487 25.2631 27.2626 24.6629 27.8628C24.0628 28.4629 23.2489 28.8 22.4002 28.8H6.4002C5.5515 28.8 4.73757 28.4629 4.13745 27.8628C3.53734 27.2626 3.2002 26.4487 3.2002 25.6V9.60002Z" fill="white"/>
                              </svg>
                            </span>

                          </div>
                          <div className="prod-upper-text-div">
                            {post.post_lease_terms.map((term, index) => (
                              <p key={index} className="price fw800 cveryblack">SR {term.price} <span className="fw500">/{translateTerm(term.lease_term)}</span></p>
                            ))}
                            <div>
                              <p className="fw500 cblack mb-0 fs12">
                                <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                                </svg>
                                {post.review_average}
                              </p>
                              <p className="cgray fs12 mb-0">({post.review_count} reviews)</p>
                            </div>
                          </div>

                          <p className="cblack fs18 mb-2">
                            <a href="#" className="cblack">{post.title}</a>
                          </p>
                          <p className="cgray fs14">
                            <svg width="18" height="21" style={{marginRight:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                            </svg>
                            {post.area && intl.locale === "en" ? post.area.en : post.area.ar}, {post.city && intl.locale === "en" ? post.city.en : post.city.ar}
                          </p>
                        </div>
                      </div>
                    )
                  })
                  : 
                  (
                    // when no post
                    <div className="row mt-5">
                      <div className="col-12">
                        <div className="text-center">
                          <img src="assets/images/no-lease-img.png" className="img-fluid pt-5 mb-4" />
                          <p className="fs30 fw700 cgray">
                            <FormattedMessage 
                              id="youhavenopost.text"
                              defaultMessage="You have no Post Yet"
                              description="You have no Post Yet"
                            />
                          </p>

                          <button className="btn btn-orang-def" onClick={onClickAddPost}>
                            <FormattedMessage 
                              id="addpost2.text"
                              defaultMessage="Add post"
                              description="Add post"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    // when no post
                  )
                  : 
                  (<div className="loader"></div>) 
                }

                 <div>
                 {moreLoading ? 
                    <div className="loader"></div>
                    :
                    null
                  }
                  {noMorePosts && userProducts && 
                  <div className="nomoreposts d-flex justify-content-center">
                      <span className="cgray fs12">
                          <FormattedMessage 
                              id="nomoreposts.text"
                              defaultMessage="No more available posts"
                              description="No more available posts"
                          />
                      </span>
                  </div>
                }
                  {!userPostsLoading && userProducts && userProducts?.data.length !== 0 && (
                    <div className="m-auto d-flex justify-content-center p-4">
                      <div className="add-post-div" onClick={onClickAddPost}>
                        <div className="text-center d-flex flex-column align-items-center p-2">
                          {/* <svg width="50" height="50" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M42 0C43.5913 0 45.1174 0.632141 46.2426 1.75736C47.3679 2.88258 48 4.4087 48 6V36H78C79.5913 36 81.1174 36.6321 82.2426 37.7574C83.3679 38.8826 84 40.4087 84 42C84 43.5913 83.3679 45.1174 82.2426 46.2426C81.1174 47.3679 79.5913 48 78 48H48V78C48 79.5913 47.3679 81.1174 46.2426 82.2426C45.1174 83.3679 43.5913 84 42 84C40.4087 84 38.8826 83.3679 37.7574 82.2426C36.6321 81.1174 36 79.5913 36 78V48H6C4.4087 48 2.88258 47.3679 1.75736 46.2426C0.632141 45.1174 0 43.5913 0 42C0 40.4087 0.632141 38.8826 1.75736 37.7574C2.88258 36.6321 4.4087 36 6 36H36V6C36 4.4087 36.6321 2.88258 37.7574 1.75736C38.8826 0.632141 40.4087 0 42 0Z" fill="#FF5833"/>
                          </svg> */}
                          <p className="fw700 fs20 m-2">
                            <FormattedMessage 
                              id="addpost2.text"
                              defaultMessage="Add post"
                              description="Add post"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                 </div>
                


                  {/* <div className="col-12">
                    {userPosts && userPosts.length !== 0 && (
                      <Pagination useFunction="getUserPosts" posts={userPosts} setUserPostsLoading={setUserPostsLoading} numLease={numLease} />
                    )}
                  </div> */}
                </div>

              </Tab>
              <Tab eventKey="reviews" title={intl.formatMessage({
                id: 'reviews.text',
                defaultMessage: 'Reviews',
                description: 'Reviews',
              })}>
                
                <div className="row pt-5">
                  {/* <div className="col-12 mb-5">
                    <p className="cblack fs30 fw700 mb-3">
                      <FormattedMessage 
                        id="yourrate.text"
                        defaultMessage="Your Rate"
                        description="Your Rate"
                      />
                    </p>
                    <p className="mb-4 d-flex">
                      {postReviews && postReviews.data.length !== 0 && (
                        starRate(postReviews.data[0])
                      )}
                    </p>
                  </div> */}
                  
                  {!postReviewsLoading ? (
                    userProductsReview && userProductsReview?.data.length !== 0 && (
                      <>
                        {userProductsReview?.data.map((rev, index) => {
    
                          return (
                            // <div key={index} className="col-12 col-lg-6">
                            //   <div  className="review-div">
                            //     <div className="d-flex">
                            //       <div className="review-img-div">
                            //         <img  src={rev.user.avatar ? rev.user.avatar : "assets/images/profpic.svg"} className="imgenclose" />
                            //       </div>
                            //       <div className="review-content-div">
                            //         <p className="mb-2 fw700 fs18 cblack d-flex justify-content-between">
                            //           {rev.user.name}
                            //         </p>
                            //         <p className="fw400 fs14 cgray">{rev.created_at}</p>
                            //         <p  className="mb-4 d-flex">
                            //           {starRate(rev)}
                            //         </p>
                            //         <p className="fs14 cblack">{rev.comment}</p>
                                    
                            //       </div>
                            //     </div>
                            //     <div className="review-post-img-div">
                            //       <img src={rev.post.post_images ? rev.post.post_images[0].image : "assets/images/review-post-img.png"} className="imgenclose" />
                            //     </div>
                            //   </div>
                            // </div>
                            <div key={index} className="col-12 col-lg-4">
                              <div className="review-div">
                                <div className="review-post-img-div">
                                  <img src={rev.post.post_images ? rev.post.post_images[0].image : "assets/images/review-post-img.png"} className="imgenclose" />
                                </div>
                                <div className="d-flex">
                                  <div className="review-content-div">
                                    <div className="d-flex justify-items-center align-items-center mb-2">
                                      <div className="imgencloseReviewProfilePicContainer">
                                        <img src={rev.user.avatar ? rev.user.avatar : "assets/images/profpic.svg"} className="imgencloseReviewProfilePic" />
                                      </div>
                                      <p className="m-0 fw700 fs12 cblack">
                                        {rev.user.name}
                                      </p>
                                    </div>
                                    <p className="m-0 fw700 fs18 cblack">{rev.post.title}</p>
                                    <p className="mb-2 d-flex">
                                      {starRate(rev)}
                                    </p>
                                    <p className="fs14 cblack mb-2">Comment: {rev.comment ? rev.comment : <span className="cgray">Not Available</span>}</p>
                                    <p  className="fw400 fs14 cgray m-0">{rev.created_at}</p>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          )
                        })}

                        {/* <Pagination useFunction="getPostReviews" posts={postReviews} numLease={numReview} setUserPostsLoading={setPostReviewsLoading} lessor_id={me && me.id} /> */}
                      </>
                    )
                  ) : (
                    <div className="loader"/>
                    
                  )}
                  {noMorePosts && 
                  <div className="nomoreposts d-flex justify-content-center">
                      <span className="cgray fs12">
                          <FormattedMessage 
                              id="nomoreposts.text"
                              defaultMessage="No more available posts"
                              description="No more available posts"
                          />
                      </span>
                  </div>
                }
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <div ref={elementRef}></div>
    </div>
  )
}

export default withRouter(Profile);
