import React, { useState, useEffect, useContext, useRef } from "react";
import Geocode from "react-geocode";
import { Modal, } from 'react-bootstrap';
import DatePickerMulti from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import Swal from 'sweetalert2';
import axios from "axios";
import Cookies from 'universal-cookie';
import {FormattedMessage, useIntl} from 'react-intl';
import MapContainer from "./mapcontainer";
import { RouteContext } from "../../contexts/route.context";
import { getDatesInRange, addMonths } from "../../util/helper";
import MyCalendar from "./calendar/mycalendar";
import MonthlyCalendar from "./monthlycalendar/monthlycalendar";
import DropOffIcon from "../../assets/images/drop-off-icon.svg";
import PickUpIcon from "../../assets/images/pick-up-icon.svg";
import Select from 'react-select';

const EditPost = () => {
  Geocode.setApiKey("AIzaSyDJCS46EQMFDDPG0MFD34yO651MzTCtpZ4");
  const intl = useIntl();
  let dtTomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  const { getCategories, categories, getSubCategories, subCategories, getArea, area, getCity, city, baseURL, apiHeaders, getViewPost,
    viewPost, } = useContext(RouteContext);
  const cookies = new Cookies();
  let edit_post_id = cookies.get('edit_post_id');
  // const [priceVal, setPriceVal] = useState("");
  const [showModalTimeStart, setShowModalTimeStart] = useState(false);
  const [numTermShow, setNumTermShow] = useState(false);
  const [timeStart, setTimeStart] = useState(false);
  const [timeChosenStart, setTimeChosenStart] = useState("");
  const [loader, setLoader] = useState(false);
  const [photoUrl, setPhotoUrl] = useState([]);
  const [listPhoto, setListPhoto] = useState([]);
  const [leaseTerm, setLeaseTerm] = useState([
    {
      term: "hourly",
      label: intl.locale === "en" ? "hourly" : "بالساعة",
      status: true,
    },
    {
      term: "daily",
      label: intl.locale === "en" ? "daily" : "يومي",
      status: true,
    },
    {
      term: "monthly",
      label: intl.locale === "en" ? "monthly" : "شهري",
      status: true,
    },
    {
      term: "fixed",
      label: intl.locale === "en" ? "fixed" : "محددة",
      status: true,
    }
  ]);
  const [leaseTermChosen, setLeaseTermChosen] = useState([
    {
      id: "",
      term: "",
      price: "",
      deposit: "",
      quantity: "",
      // date_avail: [],
      new: true,
    },
  ]);
  const [infoLoading, setInfoLoading] = useState(false);
  const [leaseTermLoading, setLeaseTermLoading] = useState(false);
  const [timeChosenLeaseType, setTimeChosenLeaseType] = useState();
  const [timeChosenLeaseIndex, setTimeChosenLeaseIndex] = useState();
  const [mapShow, setMapShow] = useState(false);
  const [addressLatLng, setAddressLatLng] = useState();
  const [featurePost, setFeaturePost] = useState(false);
  const [featurePostToggleLoading, setFeaturePostToggleLoading] = useState(false);
  const [monthlyAvailability, setMonthlyAvailability] = useState("unlimited");
  const [addressName, setAddressName] = useState();
  const [changeClaimType, setChangeClaimType] = useState();
  const [customPeriod, setCustomPeriod] = useState(true);
  let customPeriodChoices = [{en: '1', id: 1, ar: '1'},{en: '5', id: 5, ar: '5'}, {en: '10', id: 10, ar: '10'}, {en: 'Custom', id: 0, ar: 'مخصص'} ]
  const customStyles = {
    control: base => ({
      ...base,
      height: 46,
      minHeight: 35,
      marginBottom: 30,
    })
  };


  useEffect(() => {
    getCategories();
    getArea();
    getCity();
    window.scrollTo(0, 0);
    setFeaturePost(false);
    
  }, []);


  useEffect(() => {
    getViewPost(edit_post_id);
  }, [edit_post_id]);

  useEffect(() => {
    if(viewPost) {
      // console.log("My Post", viewPost)
      setChangeClaimType(viewPost?.claim_type)
      getSubCategories(viewPost.category.id);
      setLeaseTermLoading(true);
      setFeaturePostToggleLoading(true);
      setInfoLoading(true);
      setAddressName(viewPost?.location);


      // featurePost
      if(viewPost.invoice != null) {
        setFeaturePost(true);
      }
      else {
        setFeaturePost(false);
      }
      

      let leaseTermNewArr = new Array();
      
      viewPost?.post_lease_terms.map((vp) => {
        if(vp.lease_term === "hourly") {
          leaseTerm[0].status = false;
          setLeaseTerm(leaseTerm);
        }
        else if(vp.lease_term === "daily") {
          leaseTerm[1].status = false;
          setLeaseTerm(leaseTerm);
        }
        else if(vp.lease_term === "monthly") {
          leaseTerm[2].status = false;
          setLeaseTerm(leaseTerm);
        }
        // else if(vp.lease_term === "fixed") {
        //   leaseTerm[3].status = false;
        //   setLeaseTerm(leaseTerm);
        // }

        let objNew = new Object();

        objNew = {
          price: vp.price,
          deposit: vp.deposit,
          term: vp.lease_term,
          new: false,
          id: vp.id,
        }

        if(vp.lease_term === "monthly") {
          objNew.duration = vp.duration;
          objNew.time_in = "";
          objNew.time_out = "";
          // if(vp.end_date === null || vp.end_date === "null") {
          //   setMonthlyAvailability('unlimited');
          //   objNew.date_avail = [vp.post_availabilities[0].date];
          // }
          // else {
            setMonthlyAvailability('limited');
            let posAvailDatesArr = new Array();
            vp.post_availabilities.map((e) => posAvailDatesArr.push(e.date));
            objNew.date_avail = posAvailDatesArr;
          // }
        }
        else {
          let posAvailDatesArr = new Array();
          vp.post_availabilities.map((e) => posAvailDatesArr.push(e.date));

          objNew.date_avail = posAvailDatesArr;
          // objNew.duration = "";
          

          if(vp.lease_term === "hourly") {
            let timeIN = vp.time_in !== null ? vp.time_in : "0:00";
            let timeOUT = vp.time_out !== null ? vp.time_out : "0:00";

            let timeInArr = timeIN.split(":");
            let timeOutArr = timeOUT.split(":");

            // time in
            if(parseInt(timeInArr) > 12) {
              let finalTime = parseInt(timeInArr) - 12;
              timeIN = finalTime + ":00 PM";
            }
            else {
              timeIN = timeIN + " AM";
            }

            // time out
            if(parseInt(timeOutArr) > 12) {
              let finalTime = parseInt(timeOutArr) - 12;
              timeOUT = finalTime + ":00 PM";
            }
            else {
              timeOUT = timeOUT + " AM";
            }

            objNew.time_in = timeIN;
            objNew.time_out = timeOUT;
          }
          else if(vp.lease_term === "fixed"){
            objNew.period_type = vp.period_type
            objNew.period = vp.period
          }
        }

        leaseTermNewArr.push(objNew);
      })

      setLeaseTermChosen(leaseTermNewArr);
      setTimeout(() => setLeaseTermLoading(false), 800);
      setTimeout(() => setFeaturePostToggleLoading(false), 800);
      setTimeout(() => setInfoLoading(false), 800);
    }

  }, [viewPost, edit_post_id]);


  // map pin location
  const onPinMyLocation = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setAddressLatLng({
        latVal: position.coords.latitude,
        lngVal: position.coords.longitude,
      })
      // localStorage.setItem('edit_post_location', {
      //   latVal: position.coords.latitude,
      //   lngVal: position.coords.longitude,
      // })
    });
    
    setMapShow(false);
  }


  useEffect(() => {

    if(addressLatLng) {
      Geocode.fromLatLng(addressLatLng.latVal, addressLatLng.lngVal).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddressName(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }

  }, [addressLatLng]);

  const getSelectedDates = (data, index, term) => {
    // if(term === "monthly"){
    //     let dtArr = new Array();
    //     dtArr.push(data);;
    //     leaseTermChosen[index].date_avail = dtArr[0];
    //     setLeaseTermChosen(leaseTermChosen);
    // }
    if(term === "hourly") {
        let dtArr = new Array();
        dtArr.push(data);;
        leaseTermChosen[index].date_avail = dtArr[0];
        setLeaseTermChosen(leaseTermChosen);
    }
    else{
          let dtArr = new Array();
          dtArr.push(data);
          leaseTermChosen[index].date_avail = dtArr[0];
          setLeaseTermChosen(leaseTermChosen);
    } 
  }

  // price

  // time
  const onClickTime = (type, index, lease) => {
    setShowModalTimeStart(true);
    setTimeChosenLeaseType(type);
    setTimeChosenLeaseIndex(index);

    if(type === "time_in") {
      let str = lease.time_in ? lease.time_in.slice(0, -3) : "00";
      setTimeChosenStart(str);

      let splt = lease.time_in ? lease.time_in.split(" ") : "AM";
      if(splt[1] === "PM") {
        setTimeStart(true);
      }
      else {
        setTimeStart(false);
      }
    }
    else {
      let str = lease.time_out ? lease.time_out.slice(0, -3) : "00"; 
      setTimeChosenStart(str);

      let splt = lease.time_out ? lease.time_out.split(" ") : "AM";
      if(splt[1] === "PM") {
        setTimeStart(true);
      }
      else {
        setTimeStart(false);
        
      }
    }
    
  }
  const handleCloseModalStart = () => setShowModalTimeStart(false);
  const onChangeTimeStart = (e) => setTimeStart(e.target.checked);
  const onClickContinueStart = () => {
    let timeSet = timeStart ? "PM" : "AM";
    let timeString = timeChosenStart + " " + timeSet;


    if(timeChosenLeaseType === "time_in") {
      leaseTermChosen[timeChosenLeaseIndex].time_in = timeString;
      // leaseTermChosen[timeChosenLeaseIndex].new = true;
      setLeaseTermChosen(leaseTermChosen);
    }
    else {
      leaseTermChosen[timeChosenLeaseIndex].time_out = timeString;
      // leaseTermChosen[timeChosenLeaseIndex].new = true;
      setLeaseTermChosen(leaseTermChosen);
    }
    setShowModalTimeStart(false);
  }


  // images
  const onChangeImages = (e) => {
    // let reader = new FileReader();
    // let url = reader.readAsDataURL(e.target.files[0])
    // reader.onloadend = function (e) {
    //   setPhotoUrl([reader.result]);
    // }.bind();
    let listImages = new Array();
    let listImagesChosen = new Array();
    if(photoUrl.length !== 0) {
      listImages = [...photoUrl];
      listImagesChosen = [...listPhoto];
    }
    const files = e.target.files;
    for (let i=0; i < files.length; i++){
      listImages.push(URL.createObjectURL(files[i]));
      listImagesChosen.push(files[i]);
    }
    setPhotoUrl(listImages);
    setListPhoto(listImagesChosen);
  }


  // add another lease term
  const addAnotherLeaseTerm = (e) => {
    let newTermArr = [...leaseTermChosen];
    if(newTermArr.length > 2){
      setNumTermShow(true);
    }
    else{
      let termObj = {
        id: "",
        term: "",
        price: "",
        deposit:"",
        quantity: "",
        date_avail: "",
        time_in: "",
        time_out: "",
        duration: "",
        new: true,
      }
      newTermArr.push(termObj);
      setLeaseTermChosen(newTermArr);
    }
  }

  // remove lease term
  const onClickRemoveLeaseTerm = (lt, index) => {
    
    if(lt.new) {
      setLeaseTermLoading(true);

      // for lease term enable/disable
      if(leaseTermChosen[index].term !== "") {
        if(leaseTermChosen[index].term === "hourly") {
          leaseTerm[0].status = true;
          setLeaseTerm(leaseTerm);
        }
        else if(leaseTermChosen[index].term === "daily") {
          leaseTerm[1].status = true;
          setLeaseTerm(leaseTerm);
        }
        else if(leaseTermChosen[index].term === "monthly") {
          leaseTerm[2].status = true;
          setLeaseTerm(leaseTerm);
        }
        else if(leaseTermChosen[index].term === "fixed") {
          leaseTerm[3].status = true;
          setLeaseTerm(leaseTerm);
        }
      }
      // end for lease term enable/disable

      let newTermArr = [...leaseTermChosen];
      newTermArr.splice(index, 1);
      setLeaseTermChosen(newTermArr);
      setTimeout(() => setLeaseTermLoading(false), 800);
    }
    else {
      Swal.fire({
        title: 'Do you want to delete Lease Term (permanently)?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        denyButtonText: `Don't delete`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLeaseTermLoading(true);
          setFeaturePostToggleLoading(true);
          setInfoLoading(true);
          
          let result = await axios({
            url: baseURL,
            headers: apiHeaders,
            method: "post",
            data: {
              query: `
                mutation {
                  deletePostLeaseTerm(id: ${lt.id}){
                    id
                    message
                    status
                  }
                }
              `
            }
          });
      
          if(result.data.data) {
            Swal.fire({
              title: 'Deleted a Lease Term',
              text: "",
              icon: 'success',
              confirmButtonColor: '#ff9035',
            }).then((e) => {
              getViewPost(edit_post_id);
              setTimeout(() => setLeaseTermLoading(false), 1000);
              setTimeout(() => setFeaturePostToggleLoading(false), 1000);
              setTimeout(() => setInfoLoading(false), 1000);
            });
          }
          else {
  
            Swal.fire({
              title: 'Delete Lease Term Failed',
              text: result.data.errors[0].message,
              icon: 'warning',
              confirmButtonColor: '#ff9035',
            }).then((e) => {
              getViewPost(edit_post_id);
              setTimeout(() => setLeaseTermLoading(false), 1000);
              setTimeout(() => setFeaturePostToggleLoading(false), 1000);
              setTimeout(() => setInfoLoading(false), 1000);
            });
  
          }
  
        }
      });
    }
  }

  // const onChangeQuantity = (e, index) => {
  //   let val = e.target.value
  //   leaseTermChosen[index].quantity = val;
  //   setLeaseTermChosen(leaseTermChosen);
  // }

  // price
  const onChangePrice = (e, index) => {
    let val = e.target.value;
    leaseTermChosen[index].price = val;
    // leaseTermChosen[index].new = true;
    setLeaseTermChosen(leaseTermChosen);
  }

  //deposit
  const onChangeDeposit = (e, index) => {
    let val = e.target.value;
    leaseTermChosen[index].deposit = val;
    setLeaseTermChosen(leaseTermChosen);
  }

  // duration
  const onChangeDuration = (e, index) => {
    let val = e.target.value;
    leaseTermChosen[index].duration = val;
    setLeaseTermChosen(leaseTermChosen);
  }

  // availability
  const onChangeAvailability = (e) => {
    setLeaseTermLoading(true);
    setMonthlyAvailability(e.target.value);
    setTimeout(() => setLeaseTermLoading(false), 800);
  }

  // date monthly
  const onChangeDateMonthly = (e, index) => {
    if(e.constructor === Array) {
      if(e.length <= 1) {
        Swal.fire({
          title: 'Warning',
          text: "Please select end date",
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        });
      }
      else {
        let dtArr = new Array();
        e.map((dt) => {
          let dd = dt ? dt.year + "-" + dt.month.number + "-" + dt.day : "";
          dtArr.push(dd);
        });
        leaseTermChosen[index].date_avail = dtArr;
        // leaseTermChosen[index].new = true;
        setLeaseTermChosen(leaseTermChosen);
      }
    }
    else {
      let dateString = e ? e.year + "-" + e.month.number + "-" + e.day : "";
      let dtArr = new Array();
      dtArr.push(dateString);
      leaseTermChosen[index].date_avail = dtArr;
      // leaseTermChosen[index].new = true;
      setLeaseTermChosen(leaseTermChosen);
    }
  }

  // date daily and hourly
  const onChangeDateDH = (e, index, term) => {
    if(term === "hourly") {
      let dtArr = new Array();
      e.map((dt) => {
        let dd = dt ? dt.year + "-" + dt.month.number + "-" + dt.day : "";
        dtArr.push(dd);
      });
      leaseTermChosen[index].date_avail = dtArr;
      // leaseTermChosen[index].new = true;
      setLeaseTermChosen(leaseTermChosen);
    }
    else {
      let startDaily = e[0].year + "-" + e[0].month.number + "-" + e[0].day;
      let endDaily = e[1].year + "-" + e[1].month.number + "-" + e[1].day;
      let newStartDaily = new Date(startDaily);
      let newEndDaily = new Date(endDaily);
      let datesDaily = getDatesInRange(startDaily, endDaily);

      leaseTermChosen[index].date_avail = datesDaily;
      // leaseTermChosen[index].new = true;
      setLeaseTermChosen(leaseTermChosen);
    }
  }

  // terms
  const onChangeTerm = (e, index) => {
    let val = e.target.value;
   
    let termArrUnAvail = leaseTermChosen.map((un) => {
      return un.term;
    });
    setLeaseTermLoading(true);
    // if(index === 0 && val === 'fixed'){
    //   let newTermArr = [];
    //   setLeaseTermChosen(newTermArr);
    //   let termObj = {
    //     // id: leaseTermChosen[index].id,
    //     term: "fixed",
    //     price: leaseTermChosen[index].price,
    //     deposit: leaseTermChosen[index].deposit,
    //     date_avail: leaseTermChosen[index].date_avail,
    //     period_type: "",
    //     period: ""
    //   }
    //   newTermArr.push(termObj);
    //   setLeaseTermLoading(true);

    //   if(termArrUnAvail.includes(val)) {
    //     Swal.fire({
    //       title: 'Error',
    //       text: "Cannot choose the same term. refresh component",
    //       icon: 'warning',
    //       confirmButtonColor: '#FF5833',
    //     }).then((e) => {
    //       // update lease term chosen
    //       // leaseTermChosen[index].term = "";
    //       // setLeaseTermChosen(leaseTermChosen);
    //       setLeaseTermLoading(false);
    //     });
    //   }
    //   else {
    //     // update lease term chosen
    //     // leaseTermChosen[index].term = val;
    //     // leaseTermChosen[index].new = true;
    //     setLeaseTermChosen(newTermArr);
    //     console.log("asdasd", newTermArr)
        
    //     // leaseTermChosen[index].term = "fixed"
    //     // leaseTermChosen[index].period_type = ""
    //     // leaseTermChosen[index].period = ""
    //     // setLeaseTermChosen(leaseTermChosen);
    //     // setLeaseTermLoading(true);
        
    //   }
    //   setTimeout(() => setLeaseTermLoading(false), 800);
    // console.log("THISS", leaseTermChosen)
    // }

    if(val === 'fixed'){
      // console.log("leaseTermChosen.length", leaseTermChosen.length)
      if(leaseTermChosen.length > 1){
        Swal.fire({
          title: 'Error',
          text: "Delete other lease terms to proceed.",
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        }).then((e) => {
          // update lease term chosen
          
          setLeaseTermLoading(false);
        });
      }
      else{
        let newTermArr = [];
        let termObj = {
          id: leaseTermChosen[index].id,
          term: "fixed",
          price: leaseTermChosen[index].price,
          deposit: leaseTermChosen[index].deposit,
          date_avail: leaseTermChosen[index].date_avail,
          period_type: "",
          period: ""
        }
        newTermArr.push(termObj);
        setLeaseTermLoading(true);
        if(termArrUnAvail.includes(val)) {
          Swal.fire({
            title: 'Error',
            text: "Cannot choose the same term. refresh component",
            icon: 'warning',
            confirmButtonColor: '#FF5833',
          }).then((e) => {
            // update lease term chosen
            leaseTermChosen[index].term = "";
            setLeaseTermChosen(leaseTermChosen);
            setLeaseTermLoading(false);
          });
        }
        else {
          // update lease term chosen
          // leaseTermChosen[index].term = val;
          // leaseTermChosen[index].new = true;
          setLeaseTermChosen(newTermArr);
          // leaseTermChosen[index].term = "fixed"
          // leaseTermChosen[index].period_type = ""
          // leaseTermChosen[index].period = ""
          // setLeaseTermChosen(leaseTermChosen);
          setTimeout(() => setLeaseTermLoading(false), 800);
        }
      }
    }
    else{
      if(termArrUnAvail.includes(val)) {
        Swal.fire({
          title: 'Error',
          text: "Cannot choose the same term. refresh component",
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        }).then((e) => {
          // update lease term chosen
          // leaseTermChosen[index].term = "";
          setLeaseTermChosen(leaseTermChosen);
          setLeaseTermLoading(false);
        });
      }
      else {
        // update lease term chosen
        // let newTermArr = [];
        // let termObj = {
        //   id: leaseTermChosen[index].id,
        //   term: val,
        //   price: leaseTermChosen[index].price,
        //   deposit: leaseTermChosen[index].deposit,
        //   date_avail: leaseTermChosen[index].date_avail,
        // }
        leaseTermChosen[index].term = val;
        // newTermArr.push(termObj);
        leaseTermChosen[index].new = true;
        setLeaseTermChosen(leaseTermChosen);
        setTimeout(() => setLeaseTermLoading(false), 800);
      }
    }

    
    // console.log("QEEQW3")
    // for lease term enable/disable
    let chosenLt = leaseTerm.filter((e) => e.term === val);
    let oldLt;
    if(leaseTermChosen[index].term !== "") {
      oldLt = leaseTerm.filter((e) => e.term === leaseTermChosen[index].term);
    }
    
    if(chosenLt[0].term === "hourly") {
      leaseTerm[0].status = false;
      setLeaseTerm(leaseTerm);
    }
    else if(chosenLt[0].term === "daily") {
      leaseTerm[1].status = false;
      setLeaseTerm(leaseTerm);
    }
    else if(chosenLt[0].term === "monthly") {
      leaseTerm[2].status = false;
      setLeaseTerm(leaseTerm);
    }

    if(oldLt !== undefined) {
      if(oldLt[0].term === "hourly") {
        leaseTerm[0].status = true;
        setLeaseTerm(leaseTerm);
      }
      else if(oldLt[0].term === "daily") {
        leaseTerm[1].status = true;
        setLeaseTerm(leaseTerm);
      }
      else if(oldLt[0].term === "monthly") {
        leaseTerm[2].status = true;
        setLeaseTerm(leaseTerm);
      }else if(oldLt[0].term === "fixed") {
        leaseTerm[3].status = true;
        setLeaseTerm(leaseTerm);
      }
    }
    // end for lease term enable/disable

    
  }


  // delete image
  const onClickDeleteImage = (img) => {
    
    Swal.fire({
      title: 'Do you want to delete image (permanently)?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: "#25d676",
      denyButtonText: `Don't delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLeaseTermLoading(true);
        setFeaturePostToggleLoading(true);
        setInfoLoading(true);
        
        let result = await axios({
          url: baseURL,
          headers: apiHeaders,
          method: "post",
          data: {
            query: `
              mutation {
                deletePostImage(id: ${img.id}){
                  id
                  message
                  status
                }
              }
            `
          }
        });
    
        if(result.data.data) {
          Swal.fire({
            title: 'Deleted an Image',
            text: "",
            icon: 'success',
            confirmButtonColor: '#ff9035',
          }).then((e) => {
            getViewPost(edit_post_id);
            setTimeout(() => setLeaseTermLoading(false), 1000);
            setTimeout(() => setFeaturePostToggleLoading(false), 1000);
            setTimeout(() => setInfoLoading(false), 1000);
          });
        }
        else {

          Swal.fire({
            title: 'Delete Image Failed',
            text: result.data.errors[0].message,
            icon: 'warning',
            confirmButtonColor: '#ff9035',
          }).then((e) => {
            getViewPost(edit_post_id);
            setTimeout(() => setLeaseTermLoading(false), 1000);
            setTimeout(() => setFeaturePostToggleLoading(false), 1000);
            setTimeout(() => setInfoLoading(false), 1000);
          });

        }

      }
    });
  }


  const onChangeCity = (e) => {
    getArea(e.target.value);
  }

  const onChangeCategory = (e) => {
    getSubCategories(e.target.value);

  }

  const onSetToDropOff = () => {
    setChangeClaimType("drop_off")
  }

  const onSetToPickUp = () => {
    setChangeClaimType("pick_up")
  }

  const onChangePeriodType = (e, index) => {
    let val = e.target.value;
    leaseTermChosen[index].period_type = val;
    setLeaseTermChosen(leaseTermChosen);
  }

  const onChangePeriod = (e, index) => {
    let val = e.target.value;
    localStorage.setItem("period", val);
    // leaseTermChosen[index].price = val;
    leaseTermChosen[index].period = val;
    setLeaseTermChosen(leaseTermChosen);
  }

  const onChangePeriodSelect = (e, index) => {
    let val = e.id;
    if(e.en === "Custom"){
      setCustomPeriod(false);
      localStorage.removeItem("period");
      leaseTermChosen[index].period = ""
    }
    else{
      localStorage.setItem("period", val);
      leaseTermChosen[index].period = val.toString();
      setLeaseTermChosen(leaseTermChosen);
    }
  }


  const onSubmitPost = async (e) => {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData(e.target);
    let formdataObj = Object.fromEntries(formData.entries());

    let postTermsArr = new Array(); //this is for new added lease terms
    let postTermsOldArr = new Array(); //this is for old lease terms that needs to be updated
    let availStr = `available`;
    let leaseTermChosenFilter = leaseTermChosen.length !== 0 && leaseTermChosen.filter((e) => e.new === true);
    let leaseTermOldFilter = leaseTermChosen.length !== 0 && leaseTermChosen.filter((e) => e.new !== true);
    leaseTermChosenFilter.length !== 0 && leaseTermChosenFilter.map(async(term) => {

      if(term.id !== "") {
        let termObj = {
          type: `${availStr}`,
          lease_term: `${term.term}`,
          price: term.price,
          deposit: (term.deposit === "" || term.deposit === null || term.deposit === undefined) ? 0 : term.deposit,
          dates: term.date_avail,
        }
        if(term.term === "monthly") {
          if(monthlyAvailability !== "unlimited") {
            termObj.duration = term.duration;
            // let finalEnd = addMonths(term.date_avail[0], term.duration);
            // termObj.end_date = finalEnd;
            let finalEndDateSplit = term.date_avail[1].split("-");
            let finalEndDateSplitMonth = finalEndDateSplit[1].split("");
            let finalEndDateSplitDate = finalEndDateSplit[2].split("");
            let finalEndDateFinalMonth = finalEndDateSplitMonth.length === 1 ? "0" + finalEndDateSplitMonth[0] : finalEndDateSplit[1];
            let finalEndDateFinalDate = finalEndDateSplitDate.length === 1 ? "0" + finalEndDateSplitDate[0] : finalEndDateSplit[2];
            let finalEndDate = finalEndDateSplit[0] +"-"+ finalEndDateFinalMonth +"-"+ finalEndDateFinalDate;

            termObj.end_date = finalEndDate;
          }
          else {
            termObj.duration = term.duration;
            termObj.end_date = "null";
          }
        }
        else if(term.term === "hourly") {
          let timeIN = term.time_in;
          let timeOUT = term.time_out;
  
          const timeInArr = timeIN.split(" ");
          const timeOutArr = timeOUT.split(" ");
  
          if(timeInArr[1] === "PM") {
            let tInArr = timeInArr[0].split(":");
            let tInStr = parseFloat(tInArr[0]) + parseFloat(12);
            timeIN = tInStr + ":00";
          }
          else {
            timeIN = timeInArr[0];
          }
  
          if(timeOutArr[1] === "PM") {
            let tOutArr = timeOutArr[0].split(":");
            let tOutStr = parseFloat(tOutArr[0]) + parseFloat(12);
            timeOUT= tOutStr + ":00";
          }
          else {
            timeOUT = timeOutArr[0];
          }
  
  
          termObj.time_in = timeIN;
          termObj.time_out = timeOUT;
        }
        else if(term.term === "fixed"){
          termObj.period = term.period
          termObj.period_type = term.period_type
        }
        postTermsArr.push(termObj);


        // let resultDelete = await axios({
        //   url: baseURL,
        //   headers: apiHeaders,
        //   method: "post",
        //   data: {
        //     query: `
        //       mutation {
        //         deletePostLeaseTerm(id: ${term.id}){
        //           id
        //           message
        //           status
        //         }
        //       }
        //     `
        //   }
        // });
        // console.log(resultDelete);
      }
      else {
        let termObj = {
          type: `${availStr}`,
          lease_term: `${term.term}`,
          price: term.price,
          deposit: (term.deposit === "" || term.deposit === null || term.deposit === undefined) ? 0 : term.deposit,
          dates: term.date_avail,
        }
        if(term.term === "monthly") {
          if(monthlyAvailability !== "unlimited") {
            termObj.duration = term.duration;
            // let finalEnd = addMonths(term.date_avail[0], term.duration);
            // termObj.end_date = finalEnd;
            let finalEndDateSplit = term.date_avail[1].split("-");
            let finalEndDateSplitMonth = finalEndDateSplit[1].split("");
            let finalEndDateSplitDate = finalEndDateSplit[2].split("");
            let finalEndDateFinalMonth = finalEndDateSplitMonth.length === 1 ? "0" + finalEndDateSplitMonth[0] : finalEndDateSplit[1];
            let finalEndDateFinalDate = finalEndDateSplitDate.length === 1 ? "0" + finalEndDateSplitDate[0] : finalEndDateSplit[2];
            let finalEndDate = finalEndDateSplit[0] +"-"+ finalEndDateFinalMonth +"-"+ finalEndDateFinalDate;

            termObj.end_date = finalEndDate;
          }
          else {
            termObj.duration = term.duration;
            termObj.end_date = "null";
          }
        }
        else if(term.term === "hourly") {
          let timeIN = term.time_in;
          let timeOUT = term.time_out;
  
          const timeInArr = timeIN.split(" ");
          const timeOutArr = timeOUT.split(" ");
  
          if(timeInArr[1] === "PM") {
            let tInArr = timeInArr[0].split(":");
            let tInStr = parseFloat(tInArr[0]) + parseFloat(12);
            timeIN = tInStr + ":00";
          }
          else {
            timeIN = timeInArr[0];
          }
  
          if(timeOutArr[1] === "PM") {
            let tOutArr = timeOutArr[0].split(":");
            let tOutStr = parseFloat(tOutArr[0]) + parseFloat(12);
            timeOUT= tOutStr + ":00";
          }
          else {
            timeOUT = timeOutArr[0];
          }
  
  
          termObj.time_in = timeIN;
          termObj.time_out = timeOUT;
        }
        else if(term.term === "fixed"){
          termObj.period = term.period
          termObj.period_type = term.period_type
          termObj.time_in = ""
          termObj.time_out = ""
        }
  
        postTermsArr.push(termObj);
      }

    });

    // update old lease term
    leaseTermOldFilter.length !== 0 && leaseTermOldFilter.map(async(oterm) => {
      let termObj = {
        type: `${availStr}`,
        lease_term: `${oterm.term}`,
        price: oterm.price,
        deposit: (oterm.deposit === "" || oterm.deposit === null || oterm.deposit === undefined) ? 0 : oterm.deposit,
        dates: oterm.date_avail,
      }
      if(oterm.term === "monthly") {
        if(monthlyAvailability !== "unlimited") {
          termObj.duration = oterm.duration;
          // let finalEnd = addMonths(oterm.date_avail[0], oterm.duration);
          // termObj.end_date = finalEnd;
          let finalEndDateSplit = oterm.date_avail[1].split("-");
          let finalEndDateSplitMonth = finalEndDateSplit[1].split("");
          let finalEndDateSplitDate = finalEndDateSplit[2].split("");
          let finalEndDateFinalMonth = finalEndDateSplitMonth.length === 1 ? "0" + finalEndDateSplitMonth[0] : finalEndDateSplit[1];
          let finalEndDateFinalDate = finalEndDateSplitDate.length === 1 ? "0" + finalEndDateSplitDate[0] : finalEndDateSplit[2];
          let finalEndDate = finalEndDateSplit[0] +"-"+ finalEndDateFinalMonth +"-"+ finalEndDateFinalDate;

          termObj.end_date = finalEndDate;
        }
        else {
          termObj.duration = oterm.duration;
          termObj.end_date = "null";
        }
      }
      else if(oterm.term === "hourly") {
        let timeIN = oterm.time_in;
        let timeOUT = oterm.time_out;

        const timeInArr = timeIN.split(" ");
        const timeOutArr = timeOUT.split(" ");

        if(timeInArr[1] === "PM") {
          let tInArr = timeInArr[0].split(":");
          let tInStr = parseFloat(tInArr[0]) + parseFloat(12);
          timeIN = tInStr + ":00";
        }
        else {
          timeIN = timeInArr[0];
        }

        if(timeOutArr[1] === "PM") {
          let tOutArr = timeOutArr[0].split(":");
          let tOutStr = parseFloat(tOutArr[0]) + parseFloat(12);
          timeOUT= tOutStr + ":00";
        }
        else {
          timeOUT = timeOutArr[0];
        }
        termObj.time_in = timeIN;
        termObj.time_out = timeOUT;
      }
      else if(oterm.term === "fixed"){
        termObj.period = oterm.period
        termObj.period_type = oterm.period_type
        termObj.time_in = ""
        termObj.time_out = ""
      }
      termObj.post_lease_term_id = `${oterm.id}`;
      // console.log("Update Lease", termObj)
      postTermsArr.push(termObj);

      // const formDataUpdateLease = new FormData();
      // const query = `
      //   mutation($termObj: PostLeaseTermUpdateInput!){
      //     UpdateLeaseTerm(input:$termObj){
      //       id
      //       message
      //       status
      //     }
      //   }
      // `;

      
      // const operations = JSON.stringify({ query, variables: { termObj } });
      // formDataUpdateLease.append("operations", operations);
      // const map2 = {
      //     "0": [],
      // };
      // formDataUpdateLease.append("map", JSON.stringify(map2));
      // let resultUpdateLease = await axios({
      //   url: baseURL,
      //   headers: apiHeaders,
      //   method: "post",
      //   data: formDataUpdateLease
      // });


      // let resultUpdateLease = await axios({
      //   url: baseURL,
      //   method: "post",
      //   data: {
      //     query: `
      //       mutation {
      //         UpdateLeaseTerm(input:{
      //           post_lease_term_id: "${termObj.post_lease_term_id}",
      //           type: "${termObj.type}",
      //           request_type: signup
      //         }){
      //           id
      //           message
      //           status
      //         }
      //       }
      //     `
      //   }
      // });
      
    });

    let input = {
      images: null,
      post_id: viewPost?.id,
      quantity: formdataObj.quantity,
      claim_type: changeClaimType,
      title: formdataObj.name,
      category_id: formdataObj.category,
      subcategory_id: formdataObj.subcategory,
      location: formdataObj.location,
      latitude: addressLatLng !== undefined ? addressLatLng.latVal : viewPost?.latitude,
      longitude: addressLatLng !== undefined ? addressLatLng.lngVal : viewPost?.longitude,
      area_id: formdataObj.area,
      city_id: formdataObj.city,
      description: formdataObj.description,
      post_lease_terms: postTermsArr,
    };
    // console.log("query", input)
    // console.log(leaseTermChosen)
    // console.log(leaseTermOldFilter)
    

    if(formdataObj.featured_post) {

      const query = `
        mutation($input: PostUpdateInput!){
          UpdatePost(input:$input){
            id
            message
            status
          }
        }
      `;

      const operations = JSON.stringify({ query, variables: { input } });
      formData.append("operations", operations);

      const map = {
          "0": ["variables.input.images"],
      };
      formData.append("map", JSON.stringify(map));

      // upload image
      if(photoUrl.length !== 0) {
        const formDataImg = new FormData();
        let input2 = {
          post_id: viewPost.id,
          images: null,
        };

        const query = `
          mutation($input2: uploadPostImageInput!){
            UploadPostImage(input:$input2){
              id
              message
              status
            }
          }
        `;

        const operations2 = JSON.stringify({ query, variables: { input2 } });
        formDataImg.append("operations", operations2);

        const map2 = {
            "0": ["variables.input2.images"],
        };
        formDataImg.append("map", JSON.stringify(map2));

        const images = formdataObj.images ? formdataObj.images : null;
        // formDataImg.append("0", images);
        listPhoto.forEach(file=>{
          formData.append("0[]", file);
        });


        let resultImg = await axios({
          url: baseURL,
          headers: apiHeaders,
          method: "post",
          data: formDataImg
        });

      }
      // end upload image

      let result = await axios({
          url: baseURL,
          headers: apiHeaders,
          method: "post",
          data: formData
      });


      if(result.data.data) {
        cookies.set('featured_post_id', result.data.data.UpdatePost.id);

        window.location.href = "/lessor-payment";
      }
      else {
        console.log(result.data.errors)
        Swal.fire({
          title: 'Updating Post Failed',
          text: result.data.errors[0].message,
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        }).then((e) => {
          setLoader(false);
          // window.location.reload();
        });
      }
    }
    else {

      const query = `
        mutation($input: PostUpdateInput!){
          UpdatePost(input:$input){
            id
            message
            status
          }
        }
      `;

      const operations = JSON.stringify({ query, variables: { input } });
      formData.append("operations", operations);

      const map = {
          "0": ["variables.input.images"],
      };
      formData.append("map", JSON.stringify(map));

      // upload image
      if(photoUrl.length !== 0) {
        const formDataImg = new FormData();
        let input2 = {
          post_id: viewPost.id,
          images: null,
        };

        const query = `
          mutation($input2: uploadPostImageInput!){
            UploadPostImage(input:$input2){
              id
              message
              status
            }
          }
        `;

        const operations2 = JSON.stringify({ query, variables: { input2 } });
        formDataImg.append("operations", operations2);

        const map2 = {
            "0": ["variables.input2.images"],
        };
        formDataImg.append("map", JSON.stringify(map2));

        const images = formdataObj.images ? formdataObj.images : null;
        // formDataImg.append("0", images);
        listPhoto.forEach(file=>{
          formData.append("0[]", file);
        });


        let resultImg = await axios({
          url: baseURL,
          headers: apiHeaders,
          method: "post",
          data: formDataImg
        });

      }
      // end upload image

      let result = await axios({
          url: baseURL,
          headers: apiHeaders,
          method: "post",
          data: formData
      });


      if(result.data.data) {
        Swal.fire({
          title: 'Updating Post Complete',
          text: "",
          icon: 'success',
          confirmButtonColor: '#FF5833',
        }).then((e) => {
          setLoader(false);
          window.location.href = "/my-lessor-profile";
        });
      }
      else {
        
        console.log(result.data.errors)
        Swal.fire({
          title: 'Updating Post Failed',
          text: result.data.errors[0].message,
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        }).then((e) => {
          setLoader(false);
          // window.location.reload();
        });
      }
    }
  }


  return (
    <div>
      <div className="container-fluid leases-single-div py-5">
        <div className="container main-wrap">
          <form className="row" onSubmit={onSubmitPost}>
            <div className="col-12">
              <h1 className="fw700 cblack2 mb-4">
                <FormattedMessage 
                  id="editpost.text"
                  defaultMessage="Edit Post"
                  description="Edit Post"
                />
              </h1>
            </div>


            {infoLoading ? (
              <div className="loader ms-0"></div>
            ) : (
              <div className="row">
                <div className="col-12 col-lg-4">
                  <input type="text" name="name" className="form-control editpostForm" placeholder={intl.formatMessage({
                    id: 'name.text',
                    defaultMessage: "Property Title",
                    description: "Property Title",
                  })} defaultValue={viewPost?.title} required />
                </div>
                <div className="col-12 col-lg-4">
                  <select className="form-select editpostForm" name="category" onChange={onChangeCategory} required>
                    <option value="" disabled>
                      {intl.formatMessage({
                        id: 'category.text',
                        defaultMessage: 'Category',
                        description: 'Category',
                      })}
                    </option>
                    {categories && categories.map((cat, index) => (
                      <option key={index} value={cat.id} selected={viewPost?.category.id === cat.id ? true : false}>{intl.locale === "en" ? cat.en : cat.ar}</option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-lg-4">
                  <select className="form-select editpostForm" name="subcategory" required>
                    {/* <option value={viewPost?.subcategory.id}>
                    {intl.locale === "en" ? viewPost?.subcategory.en : viewPost?.subcategory.ar}
                    </option> */}
                    <option value="" disabled>
                      {intl.formatMessage({
                        id: 'subcategory.text',
                        defaultMessage: 'Subcategory',
                        description: 'Subcategory',
                      })}
                    </option>
                    {subCategories && subCategories.map((subcat, index) => (
                      // <option value={subcat.id} selected={viewPost?.category.subcategories.id === subcat.id ? true : false}>{intl.locale === "en" ? subcat.en : subcat.ar}</option>
                      <option key={index} value={subcat.id} selected={viewPost?.subcategories !== null ? (viewPost?.subcategory.id === subcat.id ? true : false) : false}>{intl.locale === "en" ? subcat.en : subcat.ar}</option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-lg-6">
                  {/* <input type="text" className="form-control editpostForm" placeholder="Location" defaultValue={viewPost?.location} name="location" 
                    onClick={() => setMapShow(true)} 
                  required/> */}
                  <textarea className="form-control editpostForm" name="location" value={addressName ? addressName : ""} placeholder={intl.formatMessage({
                    id: 'location.text',
                    defaultMessage: "Location",
                    description: "Location",
                  })} rows={2} onClick={() => setMapShow(true)} readOnly={true} required></textarea>
                </div>


                <div className="col-12 col-lg-6">
                  <select className="form-select editpostForm" name="city" required onChange={onChangeCity}>
                    <option value="">
                      {intl.formatMessage({
                        id: 'city.text',
                        defaultMessage: 'City',
                        description: 'City',
                      })}
                    </option>
                    {city && city.map((cit, index) => (
                      <option key={index} value={cit.id} selected={viewPost?.city.id === cit.id ? true : false}>{intl.locale === "en" ? cit.en : cit.ar}</option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-lg-3">
                  <select className="form-select editpostForm" name="area" required>
                    <option value="">
                      {intl.formatMessage({
                        id: 'area.text',
                        defaultMessage: 'Area',
                        description: 'Area',
                      })}
                    </option>
                    {area && area.map((ar, index) => (
                      <option key={index} value={ar.id} selected={viewPost?.area.id === ar.id ? true : false}>{intl.locale === "en" ? ar.en : ar.ar}</option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-lg-3 position-relative">
                  <input type="text" name="quantity" className="form-control editpostForm text-center"
                    placeholder={intl.formatMessage({
                      id: 'quantity.text',
                      defaultMessage: "Quantity",
                      description: "Quantity",
                    })} 
                    defaultValue={viewPost?.quantity} 
                    required
                    />
                </div>
                <div className="d-flex col-12 col-lg-6 position-relative">
                  <div className={`form-control ${changeClaimType === "pick_up" ? "claimBG" : ""} claimContainer mx-1`} onClick={onSetToPickUp}>
                    <div className="mx-2">
                      {intl.formatMessage({
                        id: 'pickup.text',
                        defaultMessage: 'Pick Up',
                        description: 'Pick Up',
                      })}
                    </div>
                    <img className={"claimIcon"} src={PickUpIcon}/>
                  </div>
                  <div className={`form-control ${changeClaimType === "drop_off" ? "claimBG" : ""}  claimContainer mx-1`} onClick={onSetToDropOff}>
                    <div className="mx-2">
                      {intl.formatMessage({
                        id: 'dropoff.text',
                        defaultMessage: 'Drop Off',
                        description: 'Drop Off',
                      })}
                    </div>
                    <img className={"claimIcon"} src={DropOffIcon}/>
                  </div>
                </div>
              </div>
            )}
            


            { !leaseTermLoading ? 
              leaseTermChosen.map((chosen, index) => (
              <div key={index} className="row">
                <div className="col-12 position-relative">
                  <div className="div-2px blue mb-4 mt-4"/>
                  <svg onClick={() => onClickRemoveLeaseTerm(chosen, index)} width="18" height="18" style={{cursor:"pointer", position:"absolute", top:"35px", right:"-10"}} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="18" height="18" rx="9" fill="#FF0000"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.86348 3.86363C4.03226 3.6949 4.26114 3.60012 4.49978 3.60012C4.73843 3.60012 4.96731 3.6949 5.13608 3.86363L8.99978 7.72733L12.8635 3.86363C12.9465 3.77767 13.0458 3.7091 13.1556 3.66194C13.2654 3.61477 13.3835 3.58994 13.503 3.5889C13.6225 3.58786 13.741 3.61063 13.8516 3.65589C13.9622 3.70114 14.0627 3.76797 14.1472 3.85247C14.2317 3.93697 14.2986 4.03746 14.3438 4.14807C14.3891 4.25867 14.4118 4.37719 14.4108 4.49669C14.4098 4.61619 14.3849 4.73429 14.3378 4.84409C14.2906 4.95389 14.222 5.0532 14.1361 5.13623L10.2724 8.99993L14.1361 12.8636C14.3 13.0334 14.3907 13.2607 14.3887 13.4967C14.3866 13.7327 14.292 13.9584 14.1251 14.1253C13.9583 14.2921 13.7325 14.3868 13.4965 14.3888C13.2606 14.3909 13.0332 14.3002 12.8635 14.1362L8.99978 10.2725L5.13608 14.1362C4.96634 14.3002 4.739 14.3909 4.50302 14.3888C4.26705 14.3868 4.04131 14.2921 3.87445 14.1253C3.70758 13.9584 3.61293 13.7327 3.61088 13.4967C3.60883 13.2607 3.69954 13.0334 3.86348 12.8636L7.72718 8.99993L3.86348 5.13623C3.69476 4.96745 3.59998 4.73857 3.59998 4.49993C3.59998 4.26128 3.69476 4.0324 3.86348 3.86363Z" fill="white"/>
                  </svg>
                </div>
                <div className="col-12 col-lg-4 position-relative">
                  {/* <svg 
                    // onClick={minusPrice} 
                    width="10" height="2" style={{cursor:"pointer", position:"absolute", top:"23px", left:"28px"}} viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillr-ule="evenodd" clipRule="evenodd" d="M0.799805 1.00002C0.799805 0.840894 0.863019 0.688282 0.975541 0.57576C1.08806 0.463239 1.24067 0.400024 1.3998 0.400024H8.5998C8.75893 0.400024 8.91155 0.463239 9.02407 0.57576C9.13659 0.688282 9.1998 0.840894 9.1998 1.00002C9.1998 1.15915 9.13659 1.31177 9.02407 1.42429C8.91155 1.53681 8.75893 1.60002 8.5998 1.60002H1.3998C1.24067 1.60002 1.08806 1.53681 0.975541 1.42429C0.863019 1.31177 0.799805 1.15915 0.799805 1.00002Z" fill="#181818"/>
                  </svg> */}

                  <input type="text" className="form-control editpostForm text-center" placeholder={intl.formatMessage({
                    id: 'price.text',
                    defaultMessage: "Price",
                    description: "Price",
                  })} defaultValue={chosen.price} onChange={(e) => onChangePrice(e, index)} 
                  required
                  />
                  
                  {/* <svg 
                    // onClick={addPrice} 
                    width="10" height="10" style={{cursor:"pointer", position:"absolute", top:"19px", right:"28px"}} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.9998 0.800049C5.15893 0.800049 5.31155 0.863263 5.42407 0.975785C5.53659 1.08831 5.5998 1.24092 5.5998 1.40005V4.40005H8.5998C8.75893 4.40005 8.91155 4.46326 9.02407 4.57578C9.13659 4.68831 9.1998 4.84092 9.1998 5.00005C9.1998 5.15918 9.13659 5.31179 9.02407 5.42431C8.91155 5.53683 8.75893 5.60005 8.5998 5.60005H5.5998V8.60005C5.5998 8.75918 5.53659 8.91179 5.42407 9.02431C5.31155 9.13684 5.15893 9.20005 4.9998 9.20005C4.84067 9.20005 4.68806 9.13684 4.57554 9.02431C4.46302 8.91179 4.3998 8.75918 4.3998 8.60005V5.60005H1.3998C1.24067 5.60005 1.08806 5.53683 0.975541 5.42431C0.863019 5.31179 0.799805 5.15918 0.799805 5.00005C0.799805 4.84092 0.863019 4.68831 0.975541 4.57578C1.08806 4.46326 1.24067 4.40005 1.3998 4.40005H4.3998V1.40005C4.3998 1.24092 4.46302 1.08831 4.57554 0.975785C4.68806 0.863263 4.84067 0.800049 4.9998 0.800049Z" fill="#444444"/>
                  </svg> */}
                </div>
                <div className="col-12 col-lg-4">
                  <input type="text" className="form-control editpostForm text-center" placeholder={intl.formatMessage({
                    id: 'deposit.text',
                    defaultMessage: "Deposit",
                    description: "Deposit",
                  })} defaultValue={chosen.deposit} onChange={(e) => onChangeDeposit(e, index)}/>
                </div> 
                <div className="col-12 col-lg-4">
                    <select className="form-select text-center editpostForm" defaultValue={chosen.term} onChange={(e) => onChangeTerm(e, index)}>
                      <option value="" disabled>
                        {intl.formatMessage({
                          id: 'leaseterm.text',
                          defaultMessage: 'Lease Term',
                          description: 'Lease Term',
                        })}
                      </option>
                      {leaseTerm.map((term, index) => {
                        if(term.status) {
                          return (
                            <option key={index} value={term.term}>{term.label.charAt(0).toUpperCase()}{term.label.slice(1)}</option>
                          )
                        }
                        else {
                          return (
                            <option key={index}  value={term.term} disabled>{term.label.charAt(0).toUpperCase()}{term.label.slice(1)}</option>
                          )
                        }
                      })}
                    </select>
                  </div>

                <div className="col-12 col-lg-6">
                  {chosen.term === "monthly" ? 
                    // monthlyAvailability === "unlimited" ? (
                      <MonthlyCalendar
                        myValue={chosen.date_avail}
                        sendSelectedDates={getSelectedDates}
                        index = {index}
                        term = {"monthly"}
                      />
                      // <DatePickerMulti
                      //   value={chosen.date_avail[0]} 
                      //   minDate={dtTomorrow}
                      //   onChange={(e) => onChangeDateMonthly(e, index)}
                      //   className="rmdp-mobile"
                      //   style={{
                      //     color: "#212529",
                      //     fontSize: "14px",
                      //     background: "transparent",
                      //     border: "1px solid #C9C9C9",
                      //     height: "46px",
                      //     marginBottom: "46px",
                      //     display: "block",
                      //     width: "100%",
                      //     padding: ".375rem .75rem",
                      //   }}
                      // />
                    // ) : (
                      // <MonthlyCalendar
                      //   myValue={chosen.date_avail}
                      //   sendSelectedDates={getSelectedDates}
                      //   index = {index}
                      //   term = {"monthly"}
                      // />
                      // <DatePickerMulti
                      //   range
                      //   value={chosen.date_avail} 
                      //   minDate={dtTomorrow}
                      //   onChange={(e) => onChangeDateMonthly(e, index)}
                      //   className="rmdp-mobile"
                      //   style={{
                      //     color: "#C9C9C9",
                      //     fontSize: "14px",
                      //     background: "transparent",
                      //     border: "1px solid #C9C9C9",
                      //     height: "46px",
                      //     marginBottom: "46px",
                      //     display: "block",
                      //     width: "100%",
                      //     padding: ".375rem .75rem",
                      //   }}
                      // />
                    // )
                  : chosen.term === "daily" ? (
                    <MyCalendar
                    myValue={chosen.date_avail}
                    sendSelectedDates={getSelectedDates}
                    index = {index}
                    term = {"daily"}
                    />
                    // <DatePickerMulti
                    //   multiple
                    //   value={chosen.date_avail} 
                    //   minDate={dtTomorrow}
                    //   onChange={(e) => onChangeDateDH(e, index, "daily")}
                    //   className="rmdp-mobile"
                    //   style={{
                    //     color: "#C9C9C9",
                    //     fontSize: "14px",
                    //     background: "transparent",
                    //     border: "1px solid #C9C9C9",
                    //     height: "46px",
                    //     marginBottom: "46px",
                    //     display: "block",
                    //     width: "100%",
                    //     padding: ".375rem .75rem",
                    //   }}
                    // />
                  ) : chosen.term === "hourly" ? (
                    <MyCalendar
                    myValue={chosen.date_avail}
                    sendSelectedDates={getSelectedDates}
                    index = {index}
                    term = {"hourly"}
                    />
                    // <DatePickerMulti 
                    //   multiple
                    //   value={chosen.date_avail} 
                    //   minDate={dtTomorrow}
                    //   onChange={(e) => onChangeDateDH(e, index, "hourly")}
                    //   className="rmdp-mobile"
                    //   style={{
                    //     color: "#C9C9C9",
                    //     fontSize: "14px",
                    //     background: "transparent",
                    //     border: "1px solid #C9C9C9",
                    //     height: "46px",
                    //     marginBottom: "46px",
                    //     display: "block",
                    //     width: "100%",
                    //     padding: ".375rem .75rem",
                    //   }}
                    // />
                  )
                  :
                  (
                    <MonthlyCalendar
                    myValue={chosen.date_avail}
                    sendSelectedDates={getSelectedDates}
                    index = {index}
                    term = {"fixed"}
                    />
                    // <DatePickerMulti 
                    //   multiple
                    //   value={chosen.date_avail} 
                    //   minDate={dtTomorrow}
                    //   onChange={(e) => onChangeDateDH(e, index, "hourly")}
                    //   className="rmdp-mobile"
                    //   style={{
                    //     color: "#C9C9C9",
                    //     fontSize: "14px",
                    //     background: "transparent",
                    //     border: "1px solid #C9C9C9",
                    //     height: "46px",
                    //     marginBottom: "46px",
                    //     display: "block",
                    //     width: "100%",
                    //     padding: ".375rem .75rem",
                    //   }}
                    // />
                  )
                }
                </div>

                {/* {chosen.term === "monthly" && (
                  <div className="col-12 col-lg-6">
                    <select className="form-select editpostForm" defaultValue={monthlyAvailability} onChange={onChangeAvailability}>
                      <option value="">
                        {intl.formatMessage({
                          id: 'typeavail.text',
                          defaultMessage: 'Type of Availability',
                          description: 'Type of Availability',
                        })}
                      </option>
                      <option value="unlimited">
                        {intl.formatMessage({
                          id: 'unlimited.text',
                          defaultMessage: 'Unlimited',
                          description: 'Unlimited',
                        })}
                      </option>
                      <option value="limited">
                        {intl.formatMessage({
                          id: 'limited.text',
                          defaultMessage: 'Limited',
                          description: 'Limited',
                        })}
                      </option>
                    </select>
                  </div>
                )} */}

                <div className="col-12 col-lg-6">
                  {chosen.term === "monthly" ? (
                    <>
                    <p className="fw600 fs16 cblack2 mb-2">
                      <svg width="18" height="18" style={{marginRight:"8px", marginTop:"-3px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM13.5 6C13.5 5.60218 13.342 5.22064 13.0607 4.93934C12.7794 4.65804 12.3978 4.5 12 4.5C11.6022 4.5 11.2206 4.65804 10.9393 4.93934C10.658 5.22064 10.5 5.60218 10.5 6V12C10.5001 12.3978 10.6582 12.7793 10.9395 13.0605L15.1815 17.304C15.3209 17.4434 15.4863 17.5539 15.6684 17.6293C15.8505 17.7048 16.0457 17.7436 16.2428 17.7436C16.4398 17.7436 16.635 17.7048 16.8171 17.6293C16.9992 17.5539 17.1646 17.4434 17.304 17.304C17.4434 17.1646 17.5539 16.9992 17.6293 16.8171C17.7048 16.635 17.7436 16.4398 17.7436 16.2428C17.7436 16.0457 17.7048 15.8505 17.6293 15.6684C17.5539 15.4863 17.4434 15.3209 17.304 15.1815L13.5 11.379V6Z" fill="#444444"/>
                      </svg>
                      <FormattedMessage 
                        id="duration.text"
                        defaultMessage="Duration"
                        description="Duration"
                      />
                    </p>
                    <select required className="form-select editpostForm" onChange={(e) => onChangeDuration(e, index)} defaultValue={chosen.duration}>
                      <option value="">
                        {intl.formatMessage({
                          id: 'selectduration.text',
                          defaultMessage: 'Select duration',
                          description: 'Select duration',
                        })}
                      </option>
                      <option value="1">1 month</option>
                      <option value="2">2 months</option>
                      <option value="3">3 months</option>
                      <option value="4">4 months</option>
                      <option value="5">5 months</option>
                      <option value="6">6 months</option>
                      <option value="7">7 months</option>
                      <option value="8">8 months</option>
                      <option value="9">9 months</option>
                      <option value="10">10 months</option>
                      <option value="11">11 months</option>
                      <option value="12">12 months</option>
                    </select>
                    </>
                  ) : chosen.term === "hourly" ? (
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <p className="fw600 fs16 cblack2 mb-2">
                          <svg width="18" height="18" style={{marginRight:"8px", marginTop:"-3px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM13.5 6C13.5 5.60218 13.342 5.22064 13.0607 4.93934C12.7794 4.65804 12.3978 4.5 12 4.5C11.6022 4.5 11.2206 4.65804 10.9393 4.93934C10.658 5.22064 10.5 5.60218 10.5 6V12C10.5001 12.3978 10.6582 12.7793 10.9395 13.0605L15.1815 17.304C15.3209 17.4434 15.4863 17.5539 15.6684 17.6293C15.8505 17.7048 16.0457 17.7436 16.2428 17.7436C16.4398 17.7436 16.635 17.7048 16.8171 17.6293C16.9992 17.5539 17.1646 17.4434 17.304 17.304C17.4434 17.1646 17.5539 16.9992 17.6293 16.8171C17.7048 16.635 17.7436 16.4398 17.7436 16.2428C17.7436 16.0457 17.7048 15.8505 17.6293 15.6684C17.5539 15.4863 17.4434 15.3209 17.304 15.1815L13.5 11.379V6Z" fill="#444444"/>
                          </svg>
                          <FormattedMessage 
                            id="from.text"
                            defaultMessage="From"
                            description="From"
                          />
                        </p>
                        <input type="text" className="form-control editpostForm w-75" placeholder={intl.formatMessage({
                          id: 'enterhere.text',
                          defaultMessage: "Enter here",
                          description: "Enter here",
                        })} onClick={() => onClickTime("time_in", index, chosen)} value={chosen.time_in} required/>
                      </div>
                      <div className="col-12 col-lg-6">
                        <p className="fw600 fs16 cblack2 mb-2">
                          <svg width="18" height="18" style={{marginRight:"8px", marginTop:"-3px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM13.5 6C13.5 5.60218 13.342 5.22064 13.0607 4.93934C12.7794 4.65804 12.3978 4.5 12 4.5C11.6022 4.5 11.2206 4.65804 10.9393 4.93934C10.658 5.22064 10.5 5.60218 10.5 6V12C10.5001 12.3978 10.6582 12.7793 10.9395 13.0605L15.1815 17.304C15.3209 17.4434 15.4863 17.5539 15.6684 17.6293C15.8505 17.7048 16.0457 17.7436 16.2428 17.7436C16.4398 17.7436 16.635 17.7048 16.8171 17.6293C16.9992 17.5539 17.1646 17.4434 17.304 17.304C17.4434 17.1646 17.5539 16.9992 17.6293 16.8171C17.7048 16.635 17.7436 16.4398 17.7436 16.2428C17.7436 16.0457 17.7048 15.8505 17.6293 15.6684C17.5539 15.4863 17.4434 15.3209 17.304 15.1815L13.5 11.379V6Z" fill="#444444"/>
                          </svg>
                          <FormattedMessage 
                            id="to.text"
                            defaultMessage="To"
                            description="To"
                          />
                        </p>
                        <input type="text" className="form-control editpostForm w-75" placeholder={intl.formatMessage({
                          id: 'enterhere.text',
                          defaultMessage: "Enter here",
                          description: "Enter here",
                        })} onClick={() => onClickTime("time_out", index, chosen)} value={chosen.time_out} required/>
                      </div>
                    </div>
                  ) : chosen.term === "fixed" &&
                  //Period Type and Period 
                  <div>
                    <div className="d-flex flex-column">
                      <div>
                        <p className="fw600 fs16 cblack2">
                          <FormattedMessage 
                            id="periodtype.text"
                            defaultMessage="Period Type"
                            description="Period Type"
                          />
                        </p>
                      </div>
                      <select className="form-select text-center editpostForm" onChange={(e) => onChangePeriodType(e, index)} defaultValue={chosen.period_type} required>
                        <option value="">
                          {intl.formatMessage({
                            id: "selectperiodtype.text",
                            defaultMessage: "Select Period Type",
                            description: "Select Period Type",
                          })}
                        </option>
                        {/* <option value="hourly">Hourly</option> */}
                        <option value="daily">{intl.locale === "en" ? "Daily" : "يوميًا"}</option>
                      </select>
                    </div>
                    <div className="d-flex flex-column">
                      <div>
                        <p className="fw600 fs16 cblack2">
                          <FormattedMessage 
                            id="period.text"
                            defaultMessage="Period"
                            description="Period"
                          />
                        </p>
                      </div>

                      <div>
                        <input type="text" name="period_type" className="form-control editpostForm text-center"
                          onChange={(e) => onChangePeriod(e, index)}  
                          placeholder={intl.formatMessage({
                            id: "setperiod.text",
                            defaultMessage: "Set Period",
                            description: "Set Period",
                          })} 
                          defaultValue={chosen.period} 
                          required
                          />
                        </div>
                      
                    </div>
                  </div>
                  }
                </div>
              </div>
              ))
            : (
              <div className="loader"></div>
            )}
            {
              leaseTermChosen[0].term !== 'fixed' &&
                <div className="d-flex mt-4">
                  <p className="fw600 fs16 cblue cur-pointer" onClick={addAnotherLeaseTerm}>
                    <svg width="15" height="15" style={{marginRight:"10px"}} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill="#1036CE" fillRule="evenodd" clipRule="evenodd" d="M8.99998 0.599976C9.31823 0.599976 9.62346 0.726404 9.8485 0.951447C10.0735 1.17649 10.2 1.48172 10.2 1.79998V7.79998H16.2C16.5182 7.79998 16.8235 7.9264 17.0485 8.15145C17.2735 8.37649 17.4 8.68172 17.4 8.99998C17.4 9.31823 17.2735 9.62346 17.0485 9.8485C16.8235 10.0735 16.5182 10.2 16.2 10.2H10.2V16.2C10.2 16.5182 10.0735 16.8235 9.8485 17.0485C9.62346 17.2735 9.31823 17.4 8.99998 17.4C8.68172 17.4 8.37649 17.2735 8.15145 17.0485C7.9264 16.8235 7.79998 16.5182 7.79998 16.2V10.2H1.79998C1.48172 10.2 1.17649 10.0735 0.951447 9.8485C0.726404 9.62346 0.599976 9.31823 0.599976 8.99998C0.599976 8.68172 0.726404 8.37649 0.951447 8.15145C1.17649 7.9264 1.48172 7.79998 1.79998 7.79998H7.79998V1.79998C7.79998 1.48172 7.9264 1.17649 8.15145 0.951447C8.37649 0.726404 8.68172 0.599976 8.99998 0.599976Z"/>
                    </svg>
                    <FormattedMessage 
                      id="addleaseterm.text"
                      defaultMessage="Add Another Lease Term"
                      description="Add Another Lease Term"
                    />
                  </p>
                </div>
            }


            <div className="col-12">
              <p className="fw600 fs16 cgray3 mb-4">
                <FormattedMessage 
                  id="createdescpost.text"
                  defaultMessage="Create Description on your post"
                  description="Create Description on your post"
                />
              </p>
              <textarea className="form-control editpostFormTextarea" rows="2" name="description" defaultValue={viewPost?.description}></textarea>
            </div>


            <div className="col-12">
              <p className="fw600 fs16 cgray3 mb-4">
                <FormattedMessage 
                  id="uploadphotos.text"
                  defaultMessage="Upload Photos"
                  description="Upload Photos"
                />
              </p>
              <div className="upload-img-post-div">
                {viewPost?.post_images.length !== 0 && viewPost?.post_images.map((img, index) => (
                  <div key={index} className="position-relative upload-col">
                    <div className="per-upload-img">
                      <img src={img.image} className="imgenclose" />
                    </div>
                    <svg onClick={() => onClickDeleteImage(img)} className="delete-icon-img" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="18" height="18" rx="9" fill="#FF0000"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M3.86348 3.86363C4.03226 3.6949 4.26114 3.60012 4.49978 3.60012C4.73843 3.60012 4.96731 3.6949 5.13608 3.86363L8.99978 7.72733L12.8635 3.86363C12.9465 3.77767 13.0458 3.7091 13.1556 3.66194C13.2654 3.61477 13.3835 3.58994 13.503 3.5889C13.6225 3.58786 13.741 3.61063 13.8516 3.65589C13.9622 3.70114 14.0627 3.76797 14.1472 3.85247C14.2317 3.93697 14.2986 4.03746 14.3438 4.14807C14.3891 4.25867 14.4118 4.37719 14.4108 4.49669C14.4098 4.61619 14.3849 4.73429 14.3378 4.84409C14.2906 4.95389 14.222 5.0532 14.1361 5.13623L10.2724 8.99993L14.1361 12.8636C14.3 13.0334 14.3907 13.2607 14.3887 13.4967C14.3866 13.7327 14.292 13.9584 14.1251 14.1253C13.9583 14.2921 13.7325 14.3868 13.4965 14.3888C13.2606 14.3909 13.0332 14.3002 12.8635 14.1362L8.99978 10.2725L5.13608 14.1362C4.96634 14.3002 4.739 14.3909 4.50302 14.3888C4.26705 14.3868 4.04131 14.2921 3.87445 14.1253C3.70758 13.9584 3.61293 13.7327 3.61088 13.4967C3.60883 13.2607 3.69954 13.0334 3.86348 12.8636L7.72718 8.99993L3.86348 5.13623C3.69476 4.96745 3.59998 4.73857 3.59998 4.49993C3.59998 4.26128 3.69476 4.0324 3.86348 3.86363Z" fill="white"/>
                    </svg>
                  </div>
                ))}

                {photoUrl.length !== 0 ? 
                  photoUrl.map((e, index) => (
                    <div key={index} className="per-upload-img upload-col">
                      <img src={e} className="imgenclose" />
                    </div>
                  ))
                : null}
                
                <div className="upload-col">
                  <input type="file" name="images" accept="image/gif, image/jpeg, image/png, image/jpg" multiple id="images-btn" onChange={onChangeImages} hidden/>
                  <label htmlFor="images-btn" className="btn btn-upload-images">
                    <div>
                      <svg width="36" height="37" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M21.4999 0.650024C22.3088 0.650024 23.0846 0.971363 23.6566 1.54335C24.2286 2.11533 24.5499 2.89111 24.5499 3.70002V18.95H39.7999C40.6088 18.95 41.3846 19.2714 41.9566 19.8433C42.5286 20.4153 42.8499 21.1911 42.8499 22C42.8499 22.8089 42.5286 23.5847 41.9566 24.1567C41.3846 24.7287 40.6088 25.05 39.7999 25.05H24.5499V40.3C24.5499 41.1089 24.2286 41.8847 23.6566 42.4567C23.0846 43.0287 22.3088 43.35 21.4999 43.35C20.691 43.35 19.9152 43.0287 19.3432 42.4567C18.7712 41.8847 18.4499 41.1089 18.4499 40.3V25.05H3.1999C2.39099 25.05 1.61521 24.7287 1.04323 24.1567C0.471241 23.5847 0.149902 22.8089 0.149902 22C0.149902 21.1911 0.471241 20.4153 1.04323 19.8433C1.61521 19.2714 2.39099 18.95 3.1999 18.95H18.4499V3.70002C18.4499 2.89111 18.7712 2.11533 19.3432 1.54335C19.9152 0.971363 20.691 0.650024 21.4999 0.650024Z" fill="#FF5833"/>
                      </svg>
                      <p className="fs16 cred mb-0 mt-3">
                        <FormattedMessage 
                          id="addphoto.text"
                          defaultMessage="Add Photo"
                          description="Add Photo"
                        />
                      </p>
                    </div>
                  </label>
                </div>
              </div>


              {/* <div className="form-check form-switch ps-0 d-flex select-time-toggle mb-4">
                <p className="fw600 fs16 cblack2 mb-2 me-5">
                  <FormattedMessage 
                    id="featurepost.text"
                    defaultMessage="Feature your Post"
                    description="Feature your Post"
                  />
                </p>
                {featurePostToggleLoading ? (
                  <div className="loader ms-0"></div>
                ) : (
                  <input className="form-check-input " type="checkbox" name="featured_post" defaultChecked={featurePost} onChange={() => setFeaturePost(!featurePost)} disabled={viewPost?.invoice != null ? true : false} />
                )}
              </div> */}


              {loader ? (
                <div className="loader ms-0"></div>
              ) : (
                <button type="submit" className="btn btn-save-post">
                  <FormattedMessage 
                    id="save.text"
                    defaultMessage="Save"
                    description="Save"
                  />
                </button>
              )}
            </div>

          
          
          </form>
        </div>
      </div>


      {/* Modal start time */}
      <Modal className="modalChat" show={showModalTimeStart} onHide={handleCloseModalStart}>
        <Modal.Body>
          <p className="fw700 cblue fs16">
            <FormattedMessage 
              id="selecttime.text"
              defaultMessage="Select a Time"
              description="Select a Time"
            />
          </p>
          <div className="form-check form-switch select-time-toggle">
            <p className={`label1 fw700 fs16 ${timeStart ? 'cgray' : 'cblue'}`}>AM</p>
            <input className="form-check-input" type="checkbox" id="toggleTimeStart" onChange={onChangeTimeStart} checked={timeStart} />
            <label className={`form-check-label fw700 fs16 ${timeStart ? 'cred' : 'cgray'}`} htmlFor="toggleTimeStart">PM</label>
          </div>


          <div className="time-list-wrapper">
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "12:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("12:00")}>12:00</span>
              <span className={`${timeChosenStart === "1:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("1:00")}>1:00</span>
            </div>
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "2:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("2:00")}>2:00</span>
              <span className={`${timeChosenStart === "3:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("3:00")}>3:00</span>
            </div>
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "4:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("4:00")}>4:00</span>
              <span className={`${timeChosenStart === "5:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("5:00")}>5:00</span>
            </div>
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "6:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("6:00")}>6:00</span>
              <span className={`${timeChosenStart === "7:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("7:00")}>7:00</span>
            </div>
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "8:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("8:00")}>8:00</span>
              <span className={`${timeChosenStart === "9:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("9:00")}>9:00</span>
            </div>
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "10:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("10:00")}>10:00</span>
              <span className={`${timeChosenStart === "11:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("11:00")}>11:00</span>
            </div>
          </div>

          <button className="btn btn-orang-def w-100 btn-continue-time mt-3" onClick={onClickContinueStart}>Continue</button>
        </Modal.Body>
      </Modal>


      {/* map modal */}
      <Modal className="modal-map-wrapper" show={mapShow} onHide={() => setMapShow(false)}>
        <Modal.Body className="modal-postshipment-div modal-map">
          
          <div className="map-modal-div">
            <MapContainer setAddressLatLng={setAddressLatLng} addressLatLng={addressLatLng} />
          </div>

          <div style={{marginTop:"30px"}}>
            <button className="btn btn-sm btn-secondary" onClick={onPinMyLocation}>Pin My Location</button>
          </div>

          <div style={{marginTop:"50px", marginBottom:"50px"}}>
            <p>
              Drag pin to your desired location
            </p>
            <button className="btn btn-primary" onClick={() => setMapShow(false)}>Pick Location</button>
          </div>
        </Modal.Body>
      </Modal>

      {/* More than 3 terms */}

      <Modal className="modal-lease-term-wrapper" show={numTermShow} onHide={() => setNumTermShow(false)}>
        <Modal.Body className="modal-postshipment-div modal-map">
          <div style={{marginTop:"10px"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 24 24" fill="none" stroke="#fb2626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
          </div>
            
          <div style={{marginTop:"25px", fontSize:"18px"}}>
            <FormattedMessage 
              id="maximumleaseterm.text"
              defaultMessage="You have exceeded the maximum number of lease terms."
              description="You have exceeded the maximum number of lease terms."
            />
          </div>

          <div style={{marginTop:"25px", marginBottom:"30px"}}>
            <button className="btn btn-danger" onClick={() => setNumTermShow(false)}>Close</button>
          </div>
        </Modal.Body>
      </Modal>
      
    </div>
  )
}

export default EditPost;
