import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import {FormattedMessage} from 'react-intl';
import { RouteContext } from "../../../contexts/route.context";
import { handleFocusNext, onKeyUpRemoveOtp, maskCharacter } from "../../../util/helper";


const Step2Lessor = () => {
  const cookies = new Cookies();
  const { baseURL, getMe, me } = useContext(RouteContext);
  let otpCode = cookies.get('code');
  let phoneNumber = cookies.get('phoneNumber');
  const [counter, setCounter] = useState(10);
  const [otpLastInput, setOtpLastInput] = useState("");
  const [otpOne, setOtpOne] = useState("")
  const [otpTwo, setOtpTwo] = useState("")
  const [otpThree, setOtpThree] = useState("")
  const [otpError, setOtpError] = useState(false);
  const [otpCodeShow, setOtpCodeShow] = useState(otpCode);
  const [loading, setLoading] = useState(true);
  const buttonOneRef = useRef();
  const buttonTwoRef = useRef();
  const buttonThreeRef = useRef();
  const buttonFourRef = useRef();
  const buttonFiveRef = useRef();

  useEffect(() => {
    getMe();
    if(me?.name !== "" && !otpCode){
      window.location.href = "/";
    }
    else{
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);


  const resendCode = async () => {
    setCounter(10);
    let roleId = 3; //lessor

    let result = await axios({
      url: baseURL,
      method: "post",
      data: {
        query: `
          mutation {
            sendOtp(input:{
              phone: "${phoneNumber}",
              role_id: "${roleId}",
              request_type: signup
            }){
              code
              new_user
              status
              token
            }
          }
        `
      }
    });

    if (result.data.data) {
      cookies.set('code', result.data.data.sendOtp.code);
      setOtpCodeShow(result.data.data.sendOtp.code);
    }
    else {
      Swal.fire({
        icon: "warning",
        title: result.data.errors[0].message,
        text:
          result.data.errors[0].extensions.reason,
      });
    }
  }

  useEffect(()=>{
    if(otpOne !== "" && otpTwo !== "" && otpThree !== "" && otpLastInput !== ""){
      onSubmitOtp()
    }
  },[otpLastInput]);
  
  const [buttonLoading, setButtonLoading] = useState(false);


  const onSubmitOtp = async (e) => {
    if(buttonLoading === true) {
      buttonOneRef.current.blur()
      buttonTwoRef.current.blur()
      buttonThreeRef.current.blur()
      buttonFourRef.current.blur()
      buttonFiveRef.current.blur()
      setButtonLoading(false)
      // e.preventDefault();
      setOtpError(false);
      let roleId = 3; //lessor
      // const formData = new FormData(e.target);
      // let formdataObj = Object.fromEntries(formData.entries());
      const otpPass = otpOne + otpTwo + otpThree + otpLastInput;
  
      
      if(otpPass === otpCodeShow) {
        
        // register user when otp success
        let result = await axios({
          url: baseURL,
          method: "post",
          data: {
            query: `
              mutation {
                registerUser(input:{
                  phone: "${phoneNumber}",
                  role_id: "${roleId}"
                }){
                  code
                  token
                  user {
                    id
                    created_at
                    role {
                      id
                    }
                  }
                }
              }
            `
          }
        });
    
        if(result.data.data) {
          // buttonOneRef.current.disabled = true;
          // buttonTwoRef.current.disabled = true;
          // buttonThreeRef.current.disabled = true;
          // buttonFourRef.current.disabled = true;
          Swal.fire({
            title: 'Registered Successfully',
            text: "",
            icon: 'success',
            confirmButtonColor: '#FF5833',
          }).then((e) => {
            cookies.set('userTkn', result.data.data.registerUser.token);
            window.location.href = "/step3-lessor";
          });
        }
        else {
          
          if(result.data.errors[0].extensions.category === "validation") {
            let errorMsg = result.data.errors[0].extensions.validation;
            let errorMsgArray = new Array();
            Object.keys(errorMsg).map((key) => {
              errorMsgArray.push(errorMsg[key][0]);
            });
    
    
            Swal.fire({
              title: 'Registration Failed',
              text: errorMsgArray.toString(),
              icon: 'warning',
              confirmButtonColor: '#FF5833',
            }).then((e) => {
              window.location.reload();
            });
          }
          else {
            Swal.fire({
              title: 'Registration Failed',
              text: result.data.errors[0].message,
              icon: 'warning',
              confirmButtonColor: '#FF5833',
            }).then((e) => {
              window.location.reload();
            });
          }
  
        }
  
      }
      else {
        setOtpError(true);
      }
    }
    
  }



  return (
    loading ?
    <div>

    </div>
    :

    <div>
      <div className="container main-wrap no-header-div">
        <div className="row">
          <div className="col-12">
            <a href="/">
              <img alt="" src="assets/images/logo.svg" />
            </a>
            <form className="verification-div text-center" onSubmit={onSubmitOtp}>
              <h1 className="cblue fw700 login-title">
                <FormattedMessage 
                  id="authencode.text"
                  defaultMessage="Authentication Code"
                  description="Authentication Code"
                />
              </h1>
              <p className="fw700 cred mb-4 fs18">
                <FormattedMessage 
                  id="step2.text"
                  defaultMessage="Step <2> of 3"
                  description="Step <2> of 3"
                />
              </p>
              <p className="cgray fw500">
                <FormattedMessage 
                  id="confirmauthcode.text"
                  defaultMessage="Please confirm your account by entering the authentication code sent to"
                  description="Please confirm your account by entering the authentication code sent to"
                /> 
                <span> </span>{maskCharacter(phoneNumber, '*', 3)}
              </p>
              
              {/* <p className="cred2 mt-2">
                For development purpose only OTP code: {otpCodeShow}
              </p> */}
              <div className="veri-input-div mt-5">
                <input ref={buttonOneRef} type="text" className="form-control veriForm-input" maxLength="1" name="otp1" id="otp1" onChange={(e) => {
                  setOtpOne(e.target.value);
                  handleFocusNext(e, 1, "otp2");
                }}/>
                <input ref={buttonTwoRef} type="text" className="form-control veriForm-input" maxLength="1" name="otp2" id="otp2" onChange={(e) => {
                  setOtpTwo(e.target.value);
                  handleFocusNext(e, 1, "otp3")
                }} onKeyUp={(e) => onKeyUpRemoveOtp(e, "otp1")}/>
                <input ref={buttonThreeRef} type="text" className="form-control veriForm-input" maxLength="1" name="otp3" id="otp3" onChange={(e) => {
                  setOtpThree(e.target.value)
                  handleFocusNext(e, 1, "otp4")
                }} onKeyUp={(e) => onKeyUpRemoveOtp(e, "otp2")}/>
                <input ref={buttonFourRef} type="text" className="form-control veriForm-input" maxLength="1" name="otp4" id="otp4" onKeyUp={(e) => onKeyUpRemoveOtp(e, "otp3")} value={otpLastInput} onChange={(e) => 
                  {
                    setOtpLastInput(e.target.value)
                    setButtonLoading(true)
                  }
                  }/>
            <input hidden type="text" ref={buttonFiveRef} className="form-control veriForm-input" maxLength="1" name="otp5" id="otp5" onChange={(e) => 
                          {
                            // setOtpLastInput(e.target.value)
                            setButtonLoading(true);
                          }} onKeyUp={(e) => onKeyUpRemoveOtp(e, "otp4")}/>
              </div>
              {/* show error msg */}
              {otpError ? (
                <p className="cred2 mt-4">
                  <svg width="18" height="18" style={{marginRight:"10px", marginLeft:"10px"}} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="18" height="18" rx="9" fill="#FF0000"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.86348 3.86363C4.03226 3.6949 4.26114 3.60012 4.49978 3.60012C4.73843 3.60012 4.96731 3.6949 5.13608 3.86363L8.99978 7.72733L12.8635 3.86363C12.9465 3.77767 13.0458 3.7091 13.1556 3.66194C13.2654 3.61477 13.3835 3.58994 13.503 3.5889C13.6225 3.58786 13.741 3.61063 13.8516 3.65589C13.9622 3.70114 14.0627 3.76797 14.1472 3.85247C14.2317 3.93697 14.2986 4.03746 14.3438 4.14807C14.3891 4.25867 14.4118 4.37719 14.4108 4.49669C14.4098 4.61619 14.3849 4.73429 14.3378 4.84409C14.2906 4.95389 14.222 5.0532 14.1361 5.13623L10.2724 8.99993L14.1361 12.8636C14.3 13.0334 14.3907 13.2607 14.3887 13.4967C14.3866 13.7327 14.292 13.9584 14.1251 14.1253C13.9583 14.2921 13.7325 14.3868 13.4965 14.3888C13.2606 14.3909 13.0332 14.3002 12.8635 14.1362L8.99978 10.2725L5.13608 14.1362C4.96634 14.3002 4.739 14.3909 4.50302 14.3888C4.26705 14.3868 4.04131 14.2921 3.87445 14.1253C3.70758 13.9584 3.61293 13.7327 3.61088 13.4967C3.60883 13.2607 3.69954 13.0334 3.86348 12.8636L7.72718 8.99993L3.86348 5.13623C3.69476 4.96745 3.59998 4.73857 3.59998 4.49993C3.59998 4.26128 3.69476 4.0324 3.86348 3.86363Z" fill="white"/>
                  </svg>
                  <FormattedMessage 
                    id="invalidotp.text"
                    defaultMessage="Invalid OTP entered. Please try again."
                    description="Invalid OTP entered. Please try again."
                  />
                </p>
              ) : ""}

              {/* <button type="submit" className="btn btn-verification mb-4" disabled={otpLastInput === "" ? true : false}>
                <FormattedMessage 
                  id="login.text"
                  defaultMessage="Login"
                  description="Login"
                />
              </button> */}
              <p className="fw500 cgray veri-send-text mt-5">
                <FormattedMessage 
                  id="haventrececode.text"
                  defaultMessage="It may take a few minutes to receive your code. Haven’t received it?"
                  description="It may take a few minutes to receive your code. Haven’t received it?"
                />  
              </p>

              <div className="resendContainer">
                <p className={`${counter <= 0 ? "sendcode-active" : "cgray"} mt-4`} onClick={counter <= 0 ? resendCode : null}>
                  <FormattedMessage 
                    id="resendcode.text"
                    defaultMessage="Resend a new code"
                    description="Resend a new code"
                  />
                </p>
                <p className="fw500 cred mt-4">
                  <FormattedMessage 
                    id="resendin.text"
                    defaultMessage="Resend in"
                    description="Resend in"
                  /> {counter}s</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step2Lessor;
