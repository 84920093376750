import React, { useState, useEffect, useContext, useRef } from "react";
import { Tab, Tabs, Dropdown } from 'react-bootstrap';
import { RouteContext } from "../contexts/route.context";
import {FormattedMessage, useIntl} from 'react-intl';
import Swal from 'sweetalert2';


const Support = () => {
  const intl = useIntl();
  const { getAllQuestions, allQuestions, getAllSettings,
    allSettings, } = useContext(RouteContext);

  useEffect(() => {
    getAllQuestions();
    getAllSettings();
  }, []);

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(allSettings.contact_no)
    Swal.fire({
      title: 'Success',
      text: allSettings.contact_no + ' copied to clipboard',
      icon: 'success',
      confirmButtonColor: '#FF5833',
    });

  }

  return (
    <div>
      <div className="container-fluid sup-div">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12">
              <h1 className="fw800 cwhite mb-4">
                <FormattedMessage
                  id="hihow.text"
                  defaultMessage="Hi, How Can We Help?"
                  description="Hi, How Can We Help?"
                />
              </h1>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid ques-div">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12 col-lg-9 mb-5">
              <h3 className="fw700 cblue mb-4 text-center">
                <FormattedMessage 
                  id="popularqu.text"
                  defaultMessage="Popular Questions"
                  description="Popular Questions"
                />
              </h3>

              <div className="ques-content">
                <div className="row mb-5">
                  {allQuestions && allQuestions.map((qu, index) => (
                    <div key={index}  className="col-12 col-md-3">
                      <div className="ques-top-each-div">
                        {intl.locale === "en" ? qu.question : qu.question_ar}
                      </div>
                    </div>
                  ))}
                </div>
                

                {allQuestions && allQuestions.map((qu, index) => (
                  <div key={index}>
                    <h3 className="fw700 cblack fs20">{intl.locale === "en" ? qu.question : qu.question_ar}</h3>
                    <p className="cblack fs16 mb-4">{intl.locale === "en" ? qu.answer : qu.answer_ar}</p>
                  </div>
                ))}
                

              </div>
            </div>
            <div className="col-12 col-lg-3 mb-5">
              <div className="contact-sup-div">
                <p className="fw700 cblack fs16 d-flex justify-content-between">
                  <FormattedMessage 
                    id="contact_on.text"
                    defaultMessage="Email us on"
                    description="Email us on"
                  />: 
                  {/* <a href={`tel:${allSettings && allSettings.contact_no}`}>
                    {allSettings && allSettings.contact_no}
                  </a> */}
                  <span className="cur-pointer support-email" onClick={copyToClipBoard}>
                    {allSettings && allSettings.contact_no}
                  </span>
                </p>
                <p className="fw700 cblack">
                  Or
                </p>
                <p className="fw700 cblack fs16 d-flex justify-content-between">
                  <FormattedMessage 
                    id="followuson.text"
                    defaultMessage="Follow us on"
                    description="Follow us on"
                  />: 
                  <span>
                  {allSettings && (
                    <span className="d-flex float-sm-end">
                      <a href={allSettings.social_link_3} target="_blank" rel="noreferrer" className="me-3">
                        <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 448 512"><path d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z"/></svg>
                      </a>
                      <a href={allSettings.social_link_4} target="_blank" rel="noreferrer" className="me-3">
                        <img alt="" src="assets/images/linkedin.svg" />
                      </a>
                      <a href={allSettings.social_link_2} target="_blank" rel="noreferrer" className="me-3">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                      </a>
                      <a href={"https://www.youtube.com/@Rentersoftware"} target="_blank" rel="noreferrer" className="me-0">
                        <svg height="20" width="20" viewBox="0 -3 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            
                          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
                              <g id="Dribbble-Light-Preview" transform="translate(-300.000000, -7442.000000)" fill="#000000">
                                  <g id="icons" transform="translate(56.000000, 160.000000)">
                                      <path d="M251.988432,7291.58588 L251.988432,7285.97425 C253.980638,7286.91168 255.523602,7287.8172 257.348463,7288.79353 C255.843351,7289.62824 253.980638,7290.56468 251.988432,7291.58588 M263.090998,7283.18289 C262.747343,7282.73013 262.161634,7282.37809 261.538073,7282.26141 C259.705243,7281.91336 248.270974,7281.91237 246.439141,7282.26141 C245.939097,7282.35515 245.493839,7282.58153 245.111335,7282.93357 C243.49964,7284.42947 244.004664,7292.45151 244.393145,7293.75096 C244.556505,7294.31342 244.767679,7294.71931 245.033639,7294.98558 C245.376298,7295.33761 245.845463,7295.57995 246.384355,7295.68865 C247.893451,7296.0008 255.668037,7296.17532 261.506198,7295.73552 C262.044094,7295.64178 262.520231,7295.39147 262.895762,7295.02447 C264.385932,7293.53455 264.28433,7285.06174 263.090998,7283.18289" id="youtube-[#168]">

                                      </path>
                                  </g>
                              </g>
                          </g>
                        </svg>
                      </a>
                    </span>
                  )}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Support;
