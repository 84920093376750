import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import {FormattedMessage, useIntl} from 'react-intl';
import { QRCode } from 'react-qrcode-logo';
import renterLogo from '../../assets/images/qrlogo.png';
import { RouteContext } from "../../contexts/route.context";
import * as htmlToImage from 'html-to-image';

const GenQrProduct = () => {
    const { getViewPost } = useContext(RouteContext);
    const { id } = useParams();
    const logoSize = 800;
    const intl = useIntl();
    const [myProduct, setMyProduct] = useState();
    const [trueDL, setTrueDL] = useState(true);

     
    useEffect(() => {
        getMyPost();
        
    }, [id]);

    useEffect(() => {
        if(!trueDL){
            setTimeout(()=>window.close(), 5000);
            
        }
    }, [trueDL]);

    useEffect(()=>{
        downloadQr()
    },[myProduct])

    const getMyPost = async () => {
        let x = await getViewPost(id);
        setMyProduct(x)
        // console.log(x)
    }

    const downloadQr = () => {
        if(renterLogo && myProduct){
            var node = document.getElementById('my-node');
            htmlToImage.toPng(node)
            .then(function (dataUrl) {
                // const url = window.URL.createObjectURL(new Blob([blob]));
                // const link = document.createElement("a");
                // link.href = url;
                
                // link.click();
                // console.log("Check")
              var img = new Image();
              img.src = dataUrl;
              document.body.appendChild(img);
              const link = document.createElement('a');
              link.href = dataUrl;
              link.download = `product-${myProduct?.title}.png`;
              link.click();
              link.remove();
              setTrueDL(false)
            })
            .catch(function (error) {
              console.error('oops, something went wrong!', error);
            });
        }
    }




    return(
    <div className="qr-container" >
        <div className="thisqr" id={"my-node"}>
            <QRCode
            size={logoSize} 
            enableCORS={true}
            ecLevel={"L"} logoImage={renterLogo} 
            logoHeight={logoSize*0.25} 
            logoWidth={logoSize*0.25} 
            logoPaddingStyle={"circle"} 
            qrStyle={"fluid"} 
            value={`https://www.renter.net/lease/${id}`} 
            id={"largeqr"}
            />
            <p 
            style={{color: "black", marginTop: -60, marginLeft: 5, textAlign: "left"}}
            >Renter.net</p>
        </div>
    </div>
    )

}

export default GenQrProduct;