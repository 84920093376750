import React, { useState, useEffect, useContext, useRef } from "react";
import {FormattedMessage} from 'react-intl';
import { RouteContext } from "../../../contexts/route.context";
import defaultImage from "../../../assets/images/profpic.svg"

const LessorBusiness = () => {

    return(
        <div className="d-flex flex-column justify-content-center align-items-center text-center mt-4">
            <h1 className={"temp-business-text-blue"}>We've got something special in store for you.</h1>
            <p>Please check back soon!</p>
        </div>
    )
}

export default LessorBusiness;