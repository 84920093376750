import React, { Component, useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';


const MapContainer = (props) => {
  let selectedLocation = props.addressLatLng;
  const [initialLocation, setInitialLocation] = useState({ lat: 24.6945876, lng: 46.4332919})

  const locationChecker = () => {
    if(selectedLocation){
      setInitialLocation({ lat: selectedLocation.latVal, lng: selectedLocation.lngVal})
    }
  }

  const mapStyles = {
    width: '100%',
    height: '100%',
  };
  
  
  const onDragMarker = (coord) => {
    let { latLng } = coord;
    let lat = latLng.lat();
    let lng = latLng.lng();
    setInitialLocation({ lat: lat, lng: lng})
    props.setAddressLatLng({
      latVal: lat,
      lngVal: lng,
    });
  }
  
  useEffect(()=>{
    locationChecker()
    // console.log(selectedLocation)
  },[])

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  
  function success(pos) {
  var crd = pos.coords;
  // setUserLatitude(crd.latitude)
  // setUserLongitude(crd.longitude)
  
  }
  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  
  
  useEffect(()=>{
        if (navigator.geolocation) {
            navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
                if (result.state === "granted") {
                //If granted then you can directly call your function here
                    // console.log(result);
                    // navigator.geolocation.getCurrentPosition((position)=>{
                    // console.log("my position", position)
                    // setUserLatitude(position.coords.latitude)
                    // setUserLongitude(position.coords.longitude)
                    // setUserLatitude(me?.latitude)
                    // setUserLongitude(me?.longitude)
                    // })
                } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission\
                // navigator.geolocation.getCurrentPosition(success, errors, options);
                } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                alert("Check browser settings to access your location")
                }
                
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }

        // if('geolocation' in navigator){
            // navigator.geolocation.getCurrentPosition((position)=>{
            // // console.log("my position", position)
            // setUserLatitude(position.coords.latitude)
            // setUserLongitude(position.coords.longitude)
            // // setUserLatitude(me?.latitude)
            // // setUserLongitude(me?.longitude)
            // })
        // }
        
  },[])



  return (
      <Map
        google={props.google}
        zoom={10}
        style={mapStyles}
        initialCenter={initialLocation}
        center={initialLocation}
        // onClick={(t, map, coord) => onDragMarker(coord)}
        
      >
        <Marker 
          position={initialLocation}
          google={props.google}
          draggable={true}
          onDragend={(t, map, coord) => onDragMarker(coord)}
        />
      </Map>
  );

}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDJCS46EQMFDDPG0MFD34yO651MzTCtpZ4'
})(MapContainer);