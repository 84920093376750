import React, { useState, useEffect, useContext, useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Tab, Tabs, Dropdown } from 'react-bootstrap';
import { RouteContext } from "../../contexts/route.context";
import { scrollToTop, translateTerm } from "../../util/helper";
import {FormattedMessage, useIntl} from 'react-intl';
import { useIntersection } from "../../util/useIntersection";
import PickUpIcon from "../../assets/images/pick-up-icon.svg";
import DropOffIcon from "../../assets/images/drop-off-icon.svg";
import Geocode from "react-geocode";

export const getDistanceFromProduct = (myLat, myLong, prodLat, prodLong) => {
    const deg2rad = (deg) => {
        return(deg * (Math.PI/180))
    }

    const round = (num) => {
        var m = Number((Math.abs(num) * 100).toPrecision(2));
        const result = Math.round(m) / 100 * Math.sign(num);
        return result;
    }
    const R = 3958.8
    const dLat = deg2rad(prodLat-myLat)
    const dLon = deg2rad(prodLong-myLong)
    const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(myLat)) * Math.cos(deg2rad(prodLat)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const d = R * c;
    const roundedNumber = round(d)
    return (roundedNumber * 1.609344)?.toFixed(1)
  }


const Leases = () => {
    const history = useHistory();
    const intl = useIntl();
    Geocode.setApiKey("AIzaSyDJCS46EQMFDDPG0MFD34yO651MzTCtpZ4");
    const { getCategories, categories, getSubCategories, subCategories, getCity, city, getArea, area, getAllPost, getPosts, posts, postsLoading, setPostsLoading, me,  getMe } = useContext(RouteContext);
    const [key, setKey] = useState('all');
    const [filtersChosenLoading, setFiltersChosenLoading] = useState(false);
    const [filterCategoryChosen, setFilterCategoryChosen] = useState();
    const [filterSubCategoryChosen, setFilterSubCategoryChosen] = useState();
    const [filterLeaseChosen, setFilterLeaseChosen] = useState();
    const [filterCityChosenId, setFilterCityChosenId] = useState();
    const [filterCityChosenName, setFilterCityChosenName] = useState("");
    const [filterAreaChosenId, setFilterAreaChosenId] = useState();
    const [filterAreaChosenName, setFilterAreaChosenName] = useState("");
    const [filterName, setFilterName] = useState("");
    const [filterFinal, setFilterFinal] = useState();
    const [isDefaultSubcat, setIsDefaultSubcat] = useState(true);
    const [numLease, setNumLease] = useState(12);
    const [pageLease, setpageLease] = useState(1);
    const [noMorePosts, setNoMorePosts] = useState(false);
    const [moreLoading, setMoreLoading] = useState(false);
    const [products, setProducts] = useState();
    const elementRef = useRef(null);
    const isVisible = useIntersection(elementRef, "0px 0px 40% 0px");
    const [toTop, setToTop] = useState(false);
    const [userLatitude, setUserLatitude] = useState(null);
    const [userLongitude, setUserLongitude] = useState(null);
    const [showDistance, setShowDistance] = useState(false)
    
 
    useEffect(() => {
        setPostsLoading(true);
        setNoMorePosts(false);
        setMoreLoading(false)
        getCategories();
        getCity();
        setPostProd();
        getMe()
        
      }, []);

      useEffect(() => {
        if (navigator.geolocation) {
            navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
        }, []);

        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          };

        function success(pos) {
        var crd = pos.coords;
        setUserLatitude(crd.latitude)
        setUserLongitude(crd.longitude)

        }
        function errors(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
        }

    useEffect(()=>{
        if (navigator.geolocation) {
            navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
                if (result.state === "granted") {
                //If granted then you can directly call your function here
                    
                    navigator.geolocation.getCurrentPosition((position)=>{
                    // console.log("my position", position)
                    setUserLatitude(position.coords.latitude)
                    setUserLongitude(position.coords.longitude)
                    // setUserLatitude(me?.latitude)
                    // setUserLongitude(me?.longitude)
                    })
                } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission\
                navigator.geolocation.getCurrentPosition(success, errors, options);
                } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                }
                
            });
        } else {
            // console.log("Geolocation is not supported by this browser.");
        }

        // if('geolocation' in navigator){
            // navigator.geolocation.getCurrentPosition((position)=>{
            // // console.log("my position", position)
            // setUserLatitude(position.coords.latitude)
            // setUserLongitude(position.coords.longitude)
            // // setUserLatitude(me?.latitude)
            // // setUserLongitude(me?.longitude)
            // })
        // }
        
    },[key, filtersChosenLoading, filterCategoryChosen, filterSubCategoryChosen, filterLeaseChosen, filterCityChosenId, filterCityChosenName, filterAreaChosenId, filterAreaChosenName])

      

      useEffect(() => {
        if (isVisible) {
        // Trigger a function when the div is visible on view port
        triggerFunc();
        }
      }, [isVisible]);

      const triggerFunc = () => {
        if(products){
            if(products.paginatorInfo.hasMorePages){
                setNoMorePosts(false);
                scrollPosts();
            }
        }
      }

      const scrollPosts = async () => {
        try{
            if(key === "all"){
                if(moreLoading === false){
                    if(me){
                        let longitude = `longitude: "${me.longitude}"`;
                        let latitude = `latitude: "${me.latitude}"`;
                        let filt = `{${longitude}, ${latitude}}`;
                        setMoreLoading(true);
                        let result = await getAllPost(numLease, products.paginatorInfo.currentPage+1, filt);
                        if(products.paginatorInfo.hasMorePages){
                            result.data.map((dat)=>{
                                products.data.push(dat);
                            })
                            products.paginatorInfo = result.paginatorInfo;
                            if(result.paginatorInfo.count < numLease || !result.paginatorInfo.hasMorePages){
                                setNoMorePosts(true);
                            }
                            setToTop(true);
                        }
                        setMoreLoading(false);
                    }
                    else{
                        setMoreLoading(true);
                        let result = await getAllPost(numLease, products.paginatorInfo.currentPage+1);
                        if(products.paginatorInfo.hasMorePages){
                            result.data.map((dat)=>{
                                products.data.push(dat);
                            })
                            products.paginatorInfo = result.paginatorInfo;
                            if(result.paginatorInfo.count < numLease || !result.paginatorInfo.hasMorePages){
                                setNoMorePosts(true);
                            }
                            setToTop(true);
                        }
                        setMoreLoading(false);
                    }
                }
            }
            else{
                if(moreLoading === false){
                    setMoreLoading(true);
                    let result = await getAllPost(numLease, products.paginatorInfo.currentPage+1, filterFinal);
                    if(products.paginatorInfo.hasMorePages){
                        result.data.map((dat)=>{
                            products.data.push(dat);
                        })
                        products.paginatorInfo = result.paginatorInfo;
                        if(result.paginatorInfo.count < numLease || !result.paginatorInfo.hasMorePages){
                            setNoMorePosts(true);
                        }
                    }
                    setMoreLoading(false);
                }
            }
        }
        catch(err){
            console.log(err);
        }
     }

      const setPostProd = async () => {
        try{
            if(me){
                let longitude = `longitude: "${me.longitude}"`;
                let latitude = `latitude: "${me.latitude}"`;
                let filt = `{${longitude}, ${latitude}}`;
                let exp = await getAllPost(numLease, pageLease, filt);
                
                // console.log(exp);
                setProducts(exp);
            }
            else{
                let exp = await getAllPost(numLease, pageLease);
                setProducts(exp);
            }
        }
        catch(err){
            console.log(err);
        }
        
      }


      useEffect(() => {
        if(key !== "all") {
            setPostsLoading(true);
            setNoMorePosts(false);
            setFilterCategoryChosen(key);
            setFilterSubCategoryChosen(undefined);
            setIsDefaultSubcat(true);
            // let inp = `{category_id: "${key}"}`;
            // getPosts(numLease, pageLease, inp);
            getFilters(key, undefined, filterLeaseChosen, filterCityChosenId, filterAreaChosenId, filterName);
        }
        else {
            setPostsLoading(true);
            setNoMorePosts(false);
            setPostProd();
            // makePost();
            // getAllPost(numLease, pageLease);
            // setProducts(posts);
        }
      }, [key]);


    const setMeToTop = () => {
        scrollToTop(0);
    }
    
      const onClickPost = (id) => {
        // setChosenPost(pt);
        scrollToTop(0);
        id && history.push(generatePath("/lease/:id", { id }));
      }
    
      const onSelectCat = (k) => {
        setKey(k);
        if(k !== "all") {
          getSubCategories(k);
        }
      }
    
      const onChangeSubCat = (e) => {
        let subcat = parseInt(e.target.value);
        setIsDefaultSubcat(false);
    
        if(!isNaN(subcat)) {
          setPostsLoading(true);
          setFilterSubCategoryChosen(subcat);
          getFilters(filterCategoryChosen, subcat, filterLeaseChosen, filterCityChosenId, filterAreaChosenId, filterName);
        }
        else {
          subcat = undefined;
          setPostsLoading(true);
          setFilterSubCategoryChosen(subcat);
    
          getFilters(filterCategoryChosen, subcat, filterLeaseChosen, filterCityChosenId, filterAreaChosenId, filterName);
        }
      }
    
      const chooseLeaseTerm = (term) => {
        setFiltersChosenLoading(true);
        setFilterLeaseChosen(term);
    
        getFilters(filterCategoryChosen, filterSubCategoryChosen, term, filterCityChosenId, filterAreaChosenId, filterName);
    
        setTimeout(() => setFiltersChosenLoading(false), 200);
      }
    
      const chooseCity = (id, name) => {
        setFiltersChosenLoading(true);
        setFilterCityChosenId(id);
        setFilterCityChosenName(name);
        getArea(id);
    
        getFilters(filterCategoryChosen, filterSubCategoryChosen, filterLeaseChosen, id, filterAreaChosenId, filterName);
        
        setTimeout(() => setFiltersChosenLoading(false), 200);
      }
    
      const chooseArea = (id, name) => {
        setFiltersChosenLoading(true);
        setFilterAreaChosenId(id);
        setFilterAreaChosenName(name);
    
        getFilters(filterCategoryChosen, filterSubCategoryChosen, filterLeaseChosen, filterCityChosenId, id, filterName);
        
        setTimeout(() => setFiltersChosenLoading(false), 200);
      }
    
      const onClickSearchName = (e) => {
        setFilterName(e.target.value);
        setFiltersChosenLoading(true);
    
        getFilters(filterCategoryChosen, filterSubCategoryChosen, filterLeaseChosen, filterCityChosenId, filterAreaChosenId, filterName);
        
        setTimeout(() => setFiltersChosenLoading(false), 200);
      }

      
      const setPostProdFil = async (fil) => {
        let exp = await getAllPost(numLease, pageLease, fil);
        setProducts(exp);
      }
    
      const getFilters = (cat, subcat, term, city, area, name) => {
        if(me){
            let catFil = cat !== undefined ? `category_id: "${cat}",` : "";
            let subcatFil = subcat !== undefined ? `subcategory_id: "${subcat}",` : "";
            let termFil = term !== undefined ? `lease_term: ${term},` : "";
            let cityFil = city !== undefined ? `city_id: "${city}",` : "";
            let areaFil = area !== undefined && city !== undefined ? `area_id: "${area}",` : "";
            let nameFil = name !== undefined ? `name: "${name}",` : "";
            let longitude = me.longitude !==undefined ? `longitude: "${me.longitude}"`:"";
            let latitude = me.latitude !==undefined ? `latitude: "${me.latitude}"`: "";
            let fil = `{${catFil} ${subcatFil} ${termFil} ${cityFil} ${areaFil} ${nameFil} ${longitude} ${latitude}}`;
            setFilterFinal(fil);
            setPostProdFil(fil);
        }
        else{
            let catFil = cat !== undefined ? `category_id: "${cat}",` : "";
            let subcatFil = subcat !== undefined ? `subcategory_id: "${subcat}",` : "";
            let termFil = term !== undefined ? `lease_term: ${term},` : "";
            let cityFil = city !== undefined ? `city_id: "${city}",` : "";
            let areaFil = area !== undefined && city !== undefined ? `area_id: "${area}",` : "";
            let nameFil = name !== undefined ? `name: "${name}",` : "";
            let fil = `{${catFil} ${subcatFil} ${termFil} ${cityFil} ${areaFil} ${nameFil}}`;

            setFilterFinal(fil);
            setPostProdFil(fil);
        }
    
        // getAllPost(numLease, pageLease, fil);
        // setProducts(posts);
      }

      const onClickRemoveFilter = () => {
        setFiltersChosenLoading(true);
        setFilterLeaseChosen();
        setFilterCityChosenId();
        setFilterCityChosenName("");
        setFilterAreaChosenId();
        setFilterAreaChosenName("");
        setFilterName("");
    
        getFilters(filterCategoryChosen, undefined, undefined, undefined, undefined);
        setTimeout(() => setFiltersChosenLoading(false), 200);
      }
    
    return(
        <div>
            <div className="container-fluid leases-div">
                <div className="container main-wrap">
                    <div className="row">
                        <div className="col-12">
                        <h1 className="fw800 cwhite mb-4">
                            <FormattedMessage 
                            id="rentalseasy.text"
                            defaultMessage="Rentals made easy!"
                            description="Rentals made easy!"
                            />
                        </h1>
                        <a className="btn btn-orang-def">
                            <FormattedMessage 
                            id="rentnow.text"
                            defaultMessage="Rent Now"
                            description="Rent Now"
                            />
                        </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End of Banner */}
            {/* Start of Filters */}
            <div className="container main-wrap product-list-div">
                <div className="row">
                    <div className="col-12">
                        <div className="filter-div mt-4">
                            <div className="sort-div">
                                {/* <p className="mb-0 cblack fs16">
                                <FormattedMessage 
                                    id="sortby.text"
                                    defaultMessage="Sort by"
                                    description="Sort by"
                                />:
                                </p> */}
                                <Dropdown>
                                <Dropdown.Toggle variant="none" className={`sort-dropdown`}>
                                    <FormattedMessage 
                                    id="leaseterm.text"
                                    defaultMessage="Lease Term"
                                    description="Lease Term"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => chooseLeaseTerm("hourly")}>
                                    <FormattedMessage 
                                        id="hourly.text"
                                        defaultMessage="Hourly"
                                        description="Hourly"
                                    />
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => chooseLeaseTerm("daily")}>
                                    <FormattedMessage 
                                        id="daily.text"
                                        defaultMessage="Daily"
                                        description="Daily"
                                    />
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => chooseLeaseTerm("monthly")}>
                                    <FormattedMessage 
                                        id="monthly.text"
                                        defaultMessage="Monthly"
                                        description="Monthly"
                                    />
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => chooseLeaseTerm("fixed")}>
                                    <FormattedMessage 
                                        id="fixed.text"
                                        defaultMessage="Fixed"
                                        description="Fixed"
                                    />
                                    </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="m-1"></div>
                                <Dropdown className="limit-h">
                                    <Dropdown.Toggle variant="none" className="sort-dropdown">
                                        <FormattedMessage 
                                        id="city.text"
                                        defaultMessage="City"
                                        description="City"
                                        />
                                    </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {city && city.map((cit, index) => (
                                    <Dropdown.Item key={index} onClick={() => chooseCity(cit.id, cit.en)}>{intl.locale === "en" ? cit.en : cit.ar}</Dropdown.Item> 
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="m-1"></div>
                            <Dropdown className="limit-h">
                                <Dropdown.Toggle variant="none" className="sort-dropdown">
                                    <FormattedMessage 
                                    id="area.text"
                                    defaultMessage="Area"
                                    description="Area"
                                    />
                                </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {area && area.map((ar, index) => (
                                <Dropdown.Item key={index} onClick={() => chooseArea(ar.id, ar.en)}>{intl.locale === "en" ? ar.en : ar.ar}</Dropdown.Item> 
                                ))}
                            </Dropdown.Menu>
                            </Dropdown>
                            </div>
                            <div className="search-div">
                                <input type="text" className="form-control" value={filterName} onChange={(e) => onClickSearchName(e)} />
                                <svg width="18" height="18" onClick={onClickSearchName} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9 3.00115C7.4087 3.00115 5.88258 3.63329 4.75736 4.75851C3.63214 5.88373 3 7.40985 3 9.00115C3 10.5924 3.63214 12.1186 4.75736 13.2438C5.88258 14.369 7.4087 15.0012 9 15.0012C10.5913 15.0012 12.1174 14.369 13.2426 13.2438C14.3679 12.1186 15 10.5924 15 9.00115C15 7.40985 14.3679 5.88373 13.2426 4.75851C12.1174 3.63329 10.5913 3.00115 9 3.00115ZM1.70192e-07 9.00115C-0.000180585 7.58471 0.333964 6.18822 0.975257 4.92526C1.61655 3.66231 2.54688 2.56855 3.69059 1.73294C4.8343 0.897329 6.15909 0.343468 7.55722 0.116399C8.95534 -0.11067 10.3873 -0.00453374 11.7367 0.426175C13.0861 0.856883 14.3147 1.6 15.3227 2.59509C16.3308 3.59018 17.0897 4.80915 17.5378 6.15285C17.9859 7.49655 18.1105 8.92704 17.9015 10.328C17.6925 11.7289 17.1558 13.0607 16.335 14.2151L23.5605 21.4406C23.8337 21.7236 23.9849 22.1025 23.9815 22.4958C23.9781 22.889 23.8203 23.2653 23.5422 23.5434C23.2641 23.8215 22.8879 23.9792 22.4946 23.9827C22.1013 23.9861 21.7224 23.8349 21.4395 23.5617L14.2155 16.3377C12.869 17.2951 11.285 17.8634 9.63701 17.9803C7.98901 18.0973 6.34061 17.7583 4.87245 17.0006C3.40429 16.2429 2.17303 15.0957 1.31359 13.6847C0.45414 12.2737 -0.000321076 10.6533 1.70192e-07 9.00115Z" fill="#242424"/>
                                </svg>
                            </div>
                        </div>
                        {filtersChosenLoading ? (
                        <div className="loader"></div>
                        ) : (
                        <p className="fw800 cveryblack mt-2">
                            <FormattedMessage 
                            id="filters.text"
                            defaultMessage="Filters"
                            description="Filters"
                            />
                            : <span className="fw500">{(filterLeaseChosen === undefined ? "" :  filterLeaseChosen) + " - " + filterCityChosenName + " - " + filterAreaChosenName + " - " + filterName} </span>
                            {filterLeaseChosen !== undefined && filterCityChosenName !== undefined && filterAreaChosenName !== undefined && filterName !== undefined ? (
                            <svg width="18" height="18" onClick={onClickRemoveFilter} style={{cursor:"pointer"}}  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="18" height="18" rx="9" fill="#FF0000"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.86348 3.86363C4.03226 3.6949 4.26114 3.60012 4.49978 3.60012C4.73843 3.60012 4.96731 3.6949 5.13608 3.86363L8.99978 7.72733L12.8635 3.86363C12.9465 3.77767 13.0458 3.7091 13.1556 3.66194C13.2654 3.61477 13.3835 3.58994 13.503 3.5889C13.6225 3.58786 13.741 3.61063 13.8516 3.65589C13.9622 3.70114 14.0627 3.76797 14.1472 3.85247C14.2317 3.93697 14.2986 4.03746 14.3438 4.14807C14.3891 4.25867 14.4118 4.37719 14.4108 4.49669C14.4098 4.61619 14.3849 4.73429 14.3378 4.84409C14.2906 4.95389 14.222 5.0532 14.1361 5.13623L10.2724 8.99993L14.1361 12.8636C14.3 13.0334 14.3907 13.2607 14.3887 13.4967C14.3866 13.7327 14.292 13.9584 14.1251 14.1253C13.9583 14.2921 13.7325 14.3868 13.4965 14.3888C13.2606 14.3909 13.0332 14.3002 12.8635 14.1362L8.99978 10.2725L5.13608 14.1362C4.96634 14.3002 4.739 14.3909 4.50302 14.3888C4.26705 14.3868 4.04131 14.2921 3.87445 14.1253C3.70758 13.9584 3.61293 13.7327 3.61088 13.4967C3.60883 13.2607 3.69954 13.0334 3.86348 12.8636L7.72718 8.99993L3.86348 5.13623C3.69476 4.96745 3.59998 4.73857 3.59998 4.49993C3.59998 4.26128 3.69476 4.0324 3.86348 3.86363Z" fill="white"/>
                            </svg>
                            ) : ""}
                        </p>  
                        )}
                        <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => onSelectCat(k)}
                        className="product-categories p-2"
                        >
                            <Tab eventKey="all" title="All">
                                <div className="row pt-5">
                                {!postsLoading ? products && 
                                    products.data.map((pt, index) => (
                                        <div key={index} className="col-12 col-lg-4 cur-pointer" onClick={() => onClickPost(pt.id)}>
                                         <div className="prod-list-single-div">
                                            <div className="prod-img-div">
                                                <div className="prod-options">
                                                    {userLatitude !== null && userLongitude !== null ? <div className="product-distance"> 
                                                        {getDistanceFromProduct(userLatitude, userLongitude, pt.latitude, pt?.longitude)} KM Away</div>: 
                                                        <div className="product-distance"> 
                                                        {(pt?.distance/1000).toFixed(1)} KM Away</div>}
                                                </div>
                                                <div className="prod-options-icon">
                                                    <img className=" product-claim-type" src={pt?.claim_type === "pick_up" ? PickUpIcon : DropOffIcon}/>
                                                </div>
                                                {/* <div className="position-absolute product-distance">{pt.distance!==null ? `${(pt.distance/1000).toFixed(1)}KM`: null}</div> */}
                                                <img src={pt.post_images.length !== 0 ? pt.post_images[0].image : "assets/images/renterlogo.svg"} className={`${pt.post_images.length !== 0 ? "imgenclose": "imgenclosex"} `} />
                                                {pt.post_images.length === 0 ? <h1 className="imgnophoto">NO AVAILABLE PHOTO</h1> : ""}
                                            </div>
                                            <div className="prod-upper-text-div">
                                                {pt.post_lease_terms.map((term, index) => (
                                                    <p key={index} className="price fw800 cveryblack">SR {term.lease_term === "fixed" ? (term.price * term.period) : term.price} {term.lease_term === "fixed" ? <span className="fw500">/ {term.period} {term.period_type === "daily" ? "Days": "Hours"} {translateTerm(term.lease_term)}</span> : <span className="fw500">/{translateTerm(term.lease_term)}</span>}
                                                    </p>
                                                ))}
                                                <div>
                                                <p className="fw500 cblack mb-0 fs12">
                                                    <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FFBF05"/>
                                                    </svg>
                                                    {pt.review_average}
                                                </p>
                                                <p className="cgray fs12 mb-0">({pt.review_count} <FormattedMessage 
                                                    id="reviews.text"
                                                    defaultMessage="Reviews"
                                                    description="Reviews"
                                                />)</p>
                                                </div>
                                            </div>

                                            <p className="cblack fs18 mb-2">
                                                <a href="#" className="cblack">{pt.title}</a>
                                            </p>
                                            <div className="d-flex">
                                                <div>
                                                    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                                                    </svg>
                                                </div>
                                                <div>
                                                    <p className="px-2 cgray fs14">
                                                        {/* {intl.locale === "en" ? (pt.area !== null ? pt.area.en : "") : (pt.area !== null ? pt.area.ar : "")} */}
                                                        {pt?.location}
                                                    </p>
                                                </div>
                                               
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    ))
                                    : (
                                    <div className="loader"></div>
                                )}
                                {/* {posts && (
                                    <Pagination  useFunction="getPosts" posts={posts} setPostsLoading={setPostsLoading} numLease={numLease} filterFinal={filterFinal} />
                                )} */}
                                        {moreLoading && 
                                        <div className="loader"></div>
                                        }
                                        {noMorePosts && products && 
                                            <div className="nomoreposts d-flex justify-content-center">
                                                <span className="cgray fs12">
                                                    <FormattedMessage 
                                                        id="nomoreposts.text"
                                                        defaultMessage="No more available posts"
                                                        description="No more available posts"
                                                    />
                                                </span>
                                            </div>
                                        }
                                        {toTop &&
                                            <div className="nomoreposts d-flex justify-content-end mt-2" onClick={setMeToTop}>
                                                <div className="bg-primary p-1 rounded-pill cursorPointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 15l-6-6-6 6"/></svg>
                                                </div>
                                            </div>
                                        }
                                </div>
                            </Tab>
                            
                            {categories && categories.map((cat, index) => (
                            <Tab key={index} eventKey={cat.id} title={intl.locale === "en" ? cat.en : cat.ar}>

                            {subCategories && (
                                <div className="row">
                                <div className="d-flex justify-content-center col-12 mt-4">
                                    <div className="w-50">
                                        <select className="form-select" onChange={onChangeSubCat}>
                                        <option value="" selected={isDefaultSubcat}>
                                            {intl.formatMessage({
                                            id: 'subcategory.text',
                                            defaultMessage: 'Subcategory',
                                            description: 'Subcategory',
                                            })}
                                        </option>
                                        {subCategories && subCategories.map((subcat, index) => (
                                            <option key={index} value={subcat.id}>{intl.locale === "en" ? subcat.en : subcat.ar}</option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                                </div>
                            )}
                            
                            {/* 
                                Filtered Posts through Categories
                            */}

                            <div className="row pt-5">
                                {!postsLoading ? products && 
                                products?.data.map((pt, index) => (
                                    <div key={index} className="col-12 col-lg-4 cur-pointer" onClick={() => onClickPost(pt.id)}>
                                        <div className="prod-list-single-div">
                                            <div className="prod-img-div">
                                            <div className="prod-options">
                                                    {userLatitude !== null && userLongitude !== null ? <div className="product-distance"> 
                                                        {getDistanceFromProduct(userLatitude, userLongitude, pt.latitude, pt?.longitude)} KM Away</div>: 
                                                        <div className="product-distance"> 
                                                        {(pt?.distance/1000).toFixed(1)} KM Away</div>}
                                                </div>
                                                <div className="prod-options-icon">
                                                    <img className=" product-claim-type" src={pt?.claim_type === "pick_up" ? PickUpIcon : DropOffIcon}/>
                                                </div>
                                            <img src={pt.post_images.length !== 0 ? pt.post_images[0].image : "assets/images/renterlogo.svg"} className={`${pt.post_images.length !== 0 ? "imgenclose": "imgenclosex"} `} />
                                            {pt.post_images.length === 0 ? <h1 className="imgnophoto">NO AVAILABLE PHOTO</h1> : ""}
                                            </div>
                                            <div className="prod-upper-text-div">
                                            {pt.post_lease_terms.map((term, index) => (
                                                <p key={index} className="price fw800 cveryblack">SR {term.price} <span className="fw500">/{translateTerm(term.lease_term)}</span></p>
                                            ))}
                                                <div>
                                                    <p className="fw500 cblack mb-0 fs12">
                                                    <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                                                    </svg>
                                                    {pt.review_average}
                                                    </p>
                                                    <p className="cgray fs12 mb-0">({pt.review_count} <FormattedMessage 
                                                    id="reviews.text"
                                                    defaultMessage="Reviews"
                                                    description="Reviews"
                                                    />)</p>
                                                </div>
                                            </div>

                                            <p className="cblack fs18 mb-2">
                                            <a href="#" className="cblack">{pt.title}</a>
                                            </p>
                                            <div className="d-flex">
                                                <div>
                                                    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                                                    </svg>
                                                </div>
                                                <div>
                                                    <p className="px-2 cgray fs14">
                                                        {/* {intl.locale === "en" ? (pt.area !== null ? pt.area.en : "") : (pt.area !== null ? pt.area.ar : "")} */}
                                                        {pt?.location}
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : (
                                <div className="loader"></div>
                                )} 
                                
                                {/* {posts && (
                                <Pagination useFunction="getPosts" posts={posts} setPostsLoading={setPostsLoading} numLease={numLease} filterFinal={filterFinal} />
                                )} */}
                                {moreLoading && 
                                    <div className="loader"></div>
                                }
                                {noMorePosts && products && 
                                    <div className="nomoreposts d-flex justify-content-center">
                                        <span className="cgray fs12">
                                            <FormattedMessage 
                                                id="nomoreposts.text"
                                                defaultMessage="No more available posts"
                                                description="No more available posts"
                                            />
                                        </span>
                                    </div>
                                }
                                {toTop &&
                                    <div className="nomoreposts d-flex justify-content-end mt-2" onClick={setMeToTop}>
                                        <div className="bg-primary p-1 rounded-pill cursorPointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 15l-6-6-6 6"/></svg>
                                        </div>
                                    </div>
                                }
                            </div>
                            </Tab>
                        ))}
                        </Tabs>
                    </div>
                </div>
            </div>
            <div ref={elementRef}></div>
        </div>
    )


}

export default Leases;
